import React, { useState } from "react";
import "./account.scss";
import Layout from "../../components/Layout";
import TabNav from "../../components/TabNav";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../components/Input";
import Card from "../../components/Card";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import usePatch from "../../hooks/rest/usePatch";
import useAlert from "../../hooks/useAlert";
import { updateProfile } from "../../redux/auth";
import { RequestForm } from "../Auth/ResetPasswordView";
import AllowEmail from "../../components/Switch/AllowEmail";

export const ACCOUNT_ROUTES = [
	{
		to: "/account/restaurant",
		label: "Algemeen",
	},
	{
		to: "/account/user",
		label: "Instellingen",
	},
	{
		to: "/account/timeslots",
		label: "Tijdsvakken & Stoelen",
	},
	{
		to: "/account/invoices",
		label: "Abonnement",
	},
];

function validateEmail(email: string) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

const UserEditView = () => {
	const auth = useSelector((state: any) => state.auth);
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();
	const [patchProfile, loading] = usePatch("/auth/profile");
	const [user, setUser] = useState({
		first_name: auth.first_name || "",
		affix: auth.affix || "",
		last_name: auth.last_name || "",
		telephone: auth.telephone || "",
		notification_email: auth.notification_email || "",
	});

	const handleUserChange = (key: string, value: any) => {
		setUser({
			...user,
			[key]: value,
		});
	};

	const handleSave = () => {
		if (
			user.notification_email &&
			!validateEmail(user.notification_email)
		) {
			sendAlert({
				type: "error",
				title: "Voer een geldig emailadres in",
				text: `${user.notification_email} is geen geldig emailadres`,
			});
			setUser({
				...user,
				notification_email: "",
			});
			return;
		}

		patchProfile(user)
			.then(({ data }) => {
				dispatch(updateProfile(data));
				sendAlert({
					type: "success",
					title: "Uw account is aangepast",
				});
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
			});
	};

	return (
		<Layout noLine title="Mijn restaurant" type="admin">
			<TabNav routes={ACCOUNT_ROUTES} />
			<div className="account-view">
				<div>
					<Card type="no-shadow">
						<StyledText bottom={5} type="bold">
							Account
						</StyledText>

						<div className="name-row">
							<Input
								value={user.first_name}
								onChange={(text) =>
									handleUserChange("first_name", text)
								}
								type="text"
								placeholder="Voornaam"
								label="Voornaam"
							/>
							<Input
								value={user.affix}
								onChange={(text) =>
									handleUserChange("affix", text)
								}
								type="text"
								placeholder="Tussenvoegsel"
								label="Tussenvoegsel"
								isOptional
							/>
							<Input
								value={user.last_name}
								onChange={(text) =>
									handleUserChange("last_name", text)
								}
								type="text"
								placeholder="Achternaam"
								label="Achternaam"
							/>
						</div>

						<Input
							value={user.telephone}
							onChange={(text) =>
								handleUserChange("telephone", text)
							}
							type="tel"
							placeholder="Telefoonnummer"
							label="Telefoonnummer"
							isOptional
						/>
						<div className="button-row button-row-account desktop-buttons">
							<RequestForm onlyButton noText />

							<Button loading={loading} onClick={handleSave}>
								Opslaan
							</Button>
						</div>
					</Card>
				</div>
				<div>
					<Card type="no-shadow">
						<StyledText type="bold">Mail notificaties</StyledText>
						<AllowEmail />
						<div
							style={{
								marginTop: 20,
							}}
						>
							<Input
								value={user.notification_email || ""}
								onChange={(text) =>
									handleUserChange("notification_email", text)
								}
								type="email"
								placeholder="Emailadres"
							/>
						</div>
						<div className="button-row button-row-account mobile-buttons">
							<RequestForm onlyButton noText />

							<Button loading={loading} onClick={handleSave}>
								Opslaan
							</Button>
						</div>
					</Card>
				</div>
			</div>
		</Layout>
	);
};

export default UserEditView;
