import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import queryString from "query-string";

const useGet = (route?: string, query?: string) => {
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState({});
	const [error, setError] = useState(null as any);

	const fetchGet = useCallback(async () => {
		console.log("fetch");
		setLoading(true);
		try {
			let { data } = (await axios.get(
				`${route}${query ? `?${query}` : ""}`
			)) as any;
			setResult(data);
			setLoading(false);
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	}, [query, route]);

	useEffect(() => {
		fetchGet();
	}, [fetchGet, route, query]);

	return [result, loading, error, fetchGet, setResult];
};

type Meta = {
	current_page: number;
	from: number;
	last_page: number;
	path: string;
	per_page: number;
	to: number;
	total: number;
};

export const usePagination = (route?: string) => {
	// const [page, setPage] = useState(startPage || 1);
	const [meta, setMeta] = useState({} as Meta);
	const [error, setError] = useState(null as any);
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState({} as any);

	const fetchGet = useCallback(async (route) => {
		return axios
			.get(`${route}`)
			.then((response: any) => {
				return response;
			})
			.catch((error) => {
				setError(error);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		fetchGet(route).then((response: any) => {
			setResult(response.data);
			setMeta(response.data.meta);
			setLoading(false);
		});
	}, [fetchGet, route]);

	const next = () => {
		if (route) {
			let { query, url } = queryString.parseUrl(route);
			let page = meta.current_page;
			if (page !== meta.last_page) {
				const q = queryString.stringify({
					...query,
					page: page + 1,
				});
				let r = `${url}?${q}`;
				fetchGet(r).then((response) => {
					const d = response.data;
					setMeta(d?.meta || {});
					setResult(d);
					let data = [...result.data, ...d.data];
					setResult({ ...response.data, data });
				});
			}
		}
	};

	return [result, loading, error, next];
};

export default useGet;
