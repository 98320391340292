import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from "react";
import "./timeslot-list.scss";
import Days from "../TimeslotPicker/Days";
import moment from "moment";
import StyledText from "../StyledText";
import useGet from "../../hooks/rest/useGet";
import { motion } from "framer-motion";
import { TimeslotProps } from "../TimeslotPickerV2/TimeslotButton";

type TimeslotListProps = {
	showButton?: boolean;
	onDateChange?: (date: Date) => void;
	selected?: any;
	onSelect: (item: any) => void;
	restaurantId?: number | string;
	showCount?: boolean;
	hasContainer?: boolean;
	isAdmin?: boolean;
};

const containerVariants = {
	enter: {
		y: 0,
		opacity: 1,
		transition: {
			when: "beforeChildren",
			staggerChildren: 0.1,
		},
	},
	exit: { y: -20, opacity: 0 },
};

const TimeslotList = (
	{
		showButton,
		onSelect,
		restaurantId,
		selected,
		showCount,
		onDateChange,
		hasContainer,
		isAdmin,
	}: TimeslotListProps,
	ref: any
) => {
	const [date, setDate] = useState(new Date());
	let url = `/restaurants/${restaurantId}/timeslots?date=${moment(
		date
	).format("YYYY-MM-DD")}`;
	if (isAdmin) {
		url += `&all=1`;
	}
	const [result, loading, , refetch] = useGet(url);

	useEffect(() => {
		if (onDateChange) {
			onDateChange(date);
		}
	}, [date, onDateChange]);

	useImperativeHandle(ref, () => ({
		fetch() {
			refetch();
		},
	}));

	const renderDays = () => (
		<Days
			value={date ? date : new Date()}
			onChange={(date) => {
				setDate(date);
				onSelect({ id: null });
			}}
		/>
	);

	const renderItems = () => {
		let items = result.data || [];
		items = items.filter((item: any) => !item.is_full);
		return (
			<>
				{loading ? (
					<Loading />
				) : items.length ? (
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={containerVariants}
						className="stagger"
					>
						{items.map((item: TimeslotProps) => (
							<Timeslot
								timeslot={item}
								key={item.id + "timeslot"}
								onClick={() => {
									onSelect({
										...item,
										query: moment(date).format(
											"YYYY-MM-DD"
										),
									});
								}}
								active={selected.id === item.id}
								disabled={item.is_full}
							/>
						))}
					</motion.div>
				) : (
					<EmptyState isPaused={result?.is_paused} />
				)}
			</>
		);
	};

	return (
		<>
			<div className="timeslot-list-head">
				{hasContainer ? (
					<div className="container-small">{renderDays()}</div>
				) : (
					renderDays()
				)}
			</div>
			<div className="timeslot-list-items">
				{hasContainer ? (
					<div className="container-small">{renderItems()}</div>
				) : (
					renderItems()
				)}
			</div>
		</>
	);
};

const timeslotVariants = {
	enter: {
		y: 0,
		opacity: 1,
	},
	exit: {
		y: -20,
		opacity: 0,
	},
};

const Timeslot = ({
	onClick,
	disabled,
	active,
	timeslot,
}: {
	onClick: () => void;
	disabled: boolean;
	active: boolean;
	timeslot: TimeslotProps;
}) => {
	const classes = ["timeslot"];
	if (active) classes.push("timeslot-active");
	if (disabled) classes.push("timeslot-disabled");
	let label = `${timeslot.time.start} - ${timeslot.time.end}`;
	let sublabel = `${timeslot.remaining} ${
		timeslot.remaining === 1 ? "plek" : "plekken"
	}`;
	return (
		<motion.div
			variants={timeslotVariants}
			onClick={onClick}
			className={classes.join(" ")}
		>
			<div className="timeslot-text">
				<StyledText className="timeslot-label">{label}</StyledText>
				<StyledText className="timeslot-sublabel">
					{sublabel}
				</StyledText>
			</div>
			<i className="fal fa-angle-right"></i>
		</motion.div>
	);
};

const EmptyState = ({ isPaused }: { isPaused?: boolean }) => (
	<div className="empty-timeslot">
		<i className="fal fa-calendar-times"></i>
		<StyledText className="empty-timeslot-label">
			{isPaused
				? "Wegens drukte kunt u vandaag niet online reserveren."
				: "Op dit moment zijn er geen plekken beschikbaar"}
		</StyledText>
		<StyledText className="empty-timeslot-sublabel">
			Probeer een andere dag of voor vandaag telefonisch.
		</StyledText>
	</div>
);

const Loading = () => (
	<div className="timeslot-list-loading">
		<i className="fal fa-spin fa-spinner-third"></i>
	</div>
);

export default forwardRef(TimeslotList);
