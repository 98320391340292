import React, { useState } from "react";
import "./contact-view.scss";
import Layout from "../../../components/Layout";
import StyledText from "../../../components/StyledText";
import usePost from "../../../hooks/rest/usePost";
import useAlert from "../../../hooks/useAlert";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const EMPTY_MAIL = {
	name: "",
	email: "",
	telephone: "",
	message: "",
	allow_email: false,
};

const ContactView = () => {
	return (
		<Layout>
			<ContactForm
				subject="Contactformulier"
				title="Laat het ons weten"
				text="Heb jij een vraag of misschien wel en goed idee? Laat het ons dan weten via onderstaand formulier."
			/>
		</Layout>
	);
};

export const ContactForm = ({
	subject,
	title,
	text,
}: {
	subject: string;
	title?: string;
	text?: string;
}) => {
	const [sendAlert] = useAlert();
	const [saved, setSaved] = useState(false);
	const [data, setData] = useState(EMPTY_MAIL as any);
	const [postMessage, loading] = usePost("/contact");

	const handleSubmit = () => {
		setSaved(true);
		if (!data.name || !data.email || !data.message) {
			sendAlert({
				type: "error",
				title: "Oeps! Er is iets misgegaan",
				text: "Vul alle velden in om een bericht te sturen",
			});
			return;
		}
		if (!data.telephone && data.allow_telephone) {
			sendAlert({
				type: "error",
				title: "Oeps! Er is iets misgegaan",
				text: "Vul alle velden in om een bericht te sturen",
			});
			return;
		}
		data.subject = subject;

		postMessage(data)
			.then(() => {
				sendAlert({
					type: "success",
					title: "Bedankt voor uw bericht",
					text: "Wij proberen uw bericht zsm te beantwoorden.",
				});
				setData(EMPTY_MAIL);
				setSaved(false);
			})
			.catch(() =>
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
					text: "Probeer het later opnieuw",
				})
			);
	};
	return (
		<section className="contact-view">
			<div className="container-small">
				<div className="info">
					{title && (
						<StyledText type="title" className="big-title">
							{title}
						</StyledText>
					)}
					{text && <StyledText>{text}</StyledText>}
				</div>
				<form
					onSubmit={(e: any) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<Input
						className="input-gray"
						placeholder="Naam"
						name="name"
						value={data.name}
						onChange={(name) => setData({ ...data, name })}
						isRequired={saved && !data.name}
					/>

					<Input
						placeholder="Email"
						className="input-gray"
						type="email"
						name="email"
						value={data.email}
						onChange={(email) => setData({ ...data, email })}
						isRequired={saved && !data.email}
					/>
					<div className="row">
						<StyledText>
							<input
								value={data.allow_telephone}
								onChange={() =>
									setData({
										...data,
										allow_telephone: !data.allow_telephone,
									})
								}
								className="row-checkbox"
								type="checkbox"
							/>
							Ik wil telefonisch contact
						</StyledText>
						<Input
							placeholder="+31 6 123 456 78"
							className="input-gray"
							value={data.telephone}
							name="telephone"
							isRequired={
								saved && data.allow_telephone && !data.email
							}
							onChange={(telephone) =>
								setData({ ...data, telephone })
							}
						/>
					</div>

					<Input
						placeholder="Bericht"
						className="input-gray"
						value={data.message}
						multiline
						onChange={(message) => setData({ ...data, message })}
						isRequired={saved && !data.message}
					/>
					<div className="button-row">
						<span></span>
						<Button onClick={handleSubmit} loading={loading}>
							Versturen
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default ContactView;
