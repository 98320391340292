import React from "react";
import Layout from "../../components/Layout";
import useGet from "../../hooks/rest/useGet";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core";
import moment from "moment";
import useAlert from "../../hooks/useAlert";
import useDelete from "../../hooks/rest/useDelete";
import usePost from "../../hooks/rest/usePost";
import Button from "../../components/Button";

const UsersView = () => {
	const [result, loading, , refetch] = useGet("/admin/users");
	const data = result.data || [];

	const typeLabel = (user: any) => {
		if (user.is_whitelisted) {
			return "Gratis toegang";
		} else if (user.is_subscribed) {
			return "Betalende klant";
		} else if (user.is_admin) {
			return "Admin account";
		}
		return "Nog niet verder dan checkout";
	};

	return (
		<Layout title="Gebruikers" loading={loading} type="admin">
			<Table className="reservation-table" aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Naam</TableCell>
						<TableCell>E-mail</TableCell>
						<TableCell>Restaurant</TableCell>
						<TableCell>Aanmaakdatum</TableCell>
						<TableCell>Type account</TableCell>
						<TableCell>Acties</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row: any) => (
						<TableRow key={row.id}>
							<TableCell component="th" scope="row">
								{row.full_name}
							</TableCell>
							<TableCell>{row.email}</TableCell>
							<TableCell>
								{row?.restaurant?.name || "Geen restaurant"}
							</TableCell>
							<TableCell>
								{moment(row.created_at).format("DD MMMM YYYY")}
							</TableCell>
							<TableCell>{typeLabel(row)}</TableCell>
							<TableCell>
								<WhitelistButton
									{...{ refetch, user: row }}
								></WhitelistButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Layout>
	);
};

const WhitelistButton = ({ refetch, user }: any) => {
	const [sendAlert] = useAlert();
	const [allowUser, loadingAllow] = usePost(`/admin/users/${user.id}/allow`);
	const [denyUser, loadingDeny] = useDelete(`/admin/users/${user.id}/deny`);
	let { is_whitelisted } = user;

	let button_type = !is_whitelisted ? "primary" : "white";

	const handleSubmit = () => {
		let text = is_whitelisted
			? "Bij bevestigen heeft de gebruiker geen gratis toegang meer"
			: "Bij bevestigen geef je de gebruiker gratis toegang";
		sendAlert({
			type: "confirm",
			title: "Weet u het zeker",
			text,
			onConfirm: () =>
				is_whitelisted
					? denyUser()
							.then(() => refetch())
							.catch(error)
					: allowUser({})
							.then(() => refetch())
							.catch(error),
		});

		const error = () =>
			sendAlert({
				type: "error",
				title: "Er is iets misgegaan",
			});
	};

	return (
		<Button
			type={button_type}
			onClick={handleSubmit}
			loading={loadingAllow || loadingDeny}
		>
			{is_whitelisted
				? "Gratis toegang blokkeren"
				: "Gratis toegang geven"}
		</Button>
	);
};

export default UsersView;
