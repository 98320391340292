import React from "react";
import "./reservation-view.scss";
import { Head } from "../RestaurantsSlotsView";
import TimeslotForm from "../../../components/TimeslotPicker/TimeslotForm";
import { useParams, useHistory } from "react-router-dom";
import useGet from "../../../hooks/rest/useGet";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import moment from "moment";

const ReservationView = () => {
	const params = useParams() as any;
	let date = params.id === "now" ? moment().format("YYYY-MM-DD") : params.id;

	let url =
		params.id && params.timeslot
			? `/restaurants/${date}/timeslots/${params.timeslot}?date=${params.date}`
			: undefined;
	const [result, loading] = useGet(url);
	const history = useHistory();

	return (
		<div className="reservation-view">
			<Head title="Bijna klaar" />
			<div className="container-small full">
				{loading ? (
					<div className="reservation-view-loading">
						<i className="fal fa-spin fa-spinner-third"></i>
						<StyledText>Een ogenblik geduld</StyledText>
					</div>
				) : result.data ? (
					<TimeslotForm
						onClear={() => history.goBack()}
						timeslot={result.data}
						restaurantId={params.id}
					/>
				) : (
					<div className="reservation-view-empty">
						<StyledText>Er is iets misgegaan</StyledText>
						<Button onClick={() => history.goBack()}>
							Terug naar tijd kiezen
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ReservationView;
