import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import moment from "moment";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../../../components/Loading";
import StyledText from "../../../components/StyledText";
import useAlert from "../../../hooks/useAlert";
import useDelete from "../../../hooks/rest/useDelete";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import PersonSlider from "../../../components/PersonSlider";
import usePatch from "../../../hooks/rest/usePatch";
import Input from "../../../components/Input";
import STATUSES from "../../../lib/statuses";
import MobileReservationItem from "./MobileReservationItem";

const ReservationTable = ({
	data,
	loading,
	query,
	onDelete,
	onShow,
	refetch,
}: {
	data: any[];
	loading: boolean;
	query?: string;
	refetch: () => void;
	onDelete: () => void;
	onShow: (reservation: any) => void;
}) => {
	if (loading) {
		return <Loading />;
	}

	if (!data.length && !loading) {
		return (
			<div className="reservation-table-empty">
				<StyledText>U heeft nog geen reservering</StyledText>
			</div>
		);
	}

	return (
		<>
			<div className="mobile-table">
				{data
					.filter((i) => {
						let q = query?.toLowerCase();
						let email = i?.email || "";
						let name = i?.name || "";

						if (name.toLowerCase().includes(q)) {
							return true;
						}
						if (email.toLowerCase().includes(q)) {
							return true;
						}
						if (
							i.telephone &&
							i.telephone.toLowerCase().includes(q)
						) {
							return true;
						}
						return false;
					})
					.map((row: any) => (
						<MobileReservationItem
							key={row.id + "reservation"}
							reservation={row}
							onShow={() => onShow(row)}
							{...{ onDelete, refetch }}
						/>
					))}
			</div>
			<Table className="reservation-table" aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell className="for-mobile">Tijdvak</TableCell>
						<TableCell className="for-mobile">Personen</TableCell>
						<TableCell className="for-mobile">Naam</TableCell>
						<TableCell>E-mail</TableCell>
						<TableCell>Telefoonnummer</TableCell>
						<TableCell>Zelfde huishouden</TableCell>
						<TableCell>Gereserveerd op</TableCell>
						<TableCell>Status</TableCell>
						<TableCell className="for-mobile">Actie</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data
						.filter((i) => {
							let q = query?.toLowerCase();
							let email = i?.email || "";
							let name = i?.name || "";

							if (name.toLowerCase().includes(q)) {
								return true;
							}
							if (email.toLowerCase().includes(q)) {
								return true;
							}
							if (
								i.telephone &&
								i.telephone.toLowerCase().includes(q)
							) {
								return true;
							}
							return false;
						})
						.map((row) => (
							<TableRow key={row.key}>
								<TableCell
									className="for-mobile"
									component="th"
									scope="row"
								>
									{`${row.timeslot.time.start} - ${row.timeslot.time.end}`}
								</TableCell>
								<TableCell className="for-mobile">
									{row?.persons || ""}
								</TableCell>
								<TableCell className="for-mobile">
									{row?.name || "Niet opgegeven"}
								</TableCell>
								<TableCell>
									{row?.email || "Niet opgegeven"}
								</TableCell>
								<TableCell>
									{row?.telephone || "Niet opgegeven"}
								</TableCell>
								<TableCell>
									{row?.is_same_household ? "Ja" : "Nee"}
								</TableCell>
								<TableCell>
									{moment(row.date, "DD-MM-YYYY").format(
										"DD MMMM YYYY"
									)}
								</TableCell>
								<TableCell>
									<span
										style={{
											color: STATUSES[row.status].color,
										}}
									>
										{STATUSES[row.status].title}
									</span>
								</TableCell>
								<TableCell className="for-mobile">
									<div className="actions">
										<div
											onClick={() => onShow(row)}
											className="icon"
										>
											{/* <i className="fas fa-address-card"></i> */}
											<img
												src={require("../../../assets/images/dashboard-icons/person-green.svg")}
												alt=""
											/>
										</div>
										<DeleteReservation
											{...{
												reservation: row,
												onDelete,
												type: "green",
											}}
										/>
										<EditReservation
											{...{
												reservation: row,
												refetch,
												type: "green",
											}}
										/>
									</div>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</>
	);
};

export const DeleteReservation = ({ reservation, onDelete, type }: any) => {
	const [deleteReservation, loading] = useDelete(
		`/reservation/${reservation.key}`
	);
	const [sendAlert] = useAlert();

	const handleDelete = () => {
		sendAlert({
			type: "confirm",
			title: "Weet u zeker dat u deze reservering wilt verwijderen",
			onConfirm: () => {
				deleteReservation()
					.then(() => {
						sendAlert({
							type: "success",
							title: "Reservering is geannuleerd",
						});
						onDelete();
					})
					.catch(() => {
						sendAlert({
							type: "error",
							title: "Er is iets misgegaan",
						});
					});
			},
		});
	};

	return (
		<div onClick={handleDelete} className="icon">
			{loading ? (
				<i className="fas fa-spin fa-spinner-third"></i>
			) : type === "green" ? (
				<img
					src={require("../../../assets/images/dashboard-icons/trash-green.svg")}
					alt=""
				/>
			) : (
				<img
					src={require("../../../assets/images/dashboard-icons/trash.svg")}
					alt=""
				/>
			)}
		</div>
	);
};

export const EditReservation = ({ reservation, refetch, type }: any) => {
	const [visible, setVisible] = useState(false);
	const [sendAlert] = useAlert();
	const [value, setValue] = useState({} || reservation) as any;
	const [updateReservation, saving] = usePatch(
		`/reservation/${reservation.key}`
	);

	useEffect(() => {
		setValue(reservation);
	}, [reservation, setValue]);

	const timeslot = reservation.timeslot || {};
	const max_persons = timeslot.remaining + reservation.persons;

	const handleSave = () => {
		updateReservation({
			persons: value.persons,
			telephone: value.telephone,
			name: value.name,
			email: value.email,
		}).then(() => {
			let text = `De reservering is aangepast`;
			setVisible(false);
			sendAlert({
				type: "success",
				title: `Succesvol aangepast`,
				text,
			});
			refetch();
		});
	};

	return (
		<>
			<Modal
				title="Reservering wijzigen"
				visible={visible}
				onClose={() => setVisible(false)}
				className="reservation-edit"
			>
				<div className="form-group">
					<PersonSlider
						max={max_persons}
						onChange={(persons) => {
							setValue({ ...value, persons });
						}}
						value={value.persons}
						label="Aantal personen aanpassen"
					/>
				</div>
				<div className="form-group">
					<Input
						value={value.email}
						onChange={(text) => setValue({ ...value, email: text })}
						type="email"
						placeholder="Emailadres"
						label="Emailadres"
						name="email"
					/>
				</div>
				<div className="form-group">
					<Input
						onChange={(telephone) =>
							setValue({ ...value, telephone })
						}
						value={value.telephone}
						placeholder="Telefoonnummer"
					/>
				</div>
				<div className="form-group">
					<Input
						onChange={(name) => setValue({ ...value, name })}
						value={value.name}
						placeholder="Naam"
					/>
				</div>
				<div className="form-group">
					<Button
						type="gray"
						loading={saving}
						onClick={handleSave}
						isSave
					/>
				</div>
			</Modal>
			<div onClick={() => setVisible(true)} className="icon">
				{/* <i className="fas fa-pencil"></i> */}
				{type === "green" ? (
					<img
						src={require("../../../assets/images/dashboard-icons/pencil-green.svg")}
						alt=""
					/>
				) : (
					<img
						src={require("../../../assets/images/dashboard-icons/pencil.svg")}
						alt=""
					/>
				)}
			</div>
		</>
	);
};

export default ReservationTable;
