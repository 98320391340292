import React from "react";
import Dropdown from ".";
import useGet from "../../hooks/rest/useGet";

type CategoryDropdownProps = {
	value?: number;
	onChange: (value: number) => void;
	isRequired?: boolean;
};

const CategoryDropdown = ({
	value,
	onChange,
	isRequired,
}: CategoryDropdownProps) => {
	const [result] = useGet("/categories");
	const data =
		result && result?.data?.length
			? result.data.map((item: any) => ({
					value: item.id,
					label: item.name,
			  }))
			: [];

	return (
		<Dropdown
			placeholder="Restaurant type"
			label="Restaurant type"
			value={value}
			onChange={onChange}
			data={data}
			search
			isRequired={isRequired}
		/>
	);
};

export default CategoryDropdown;
