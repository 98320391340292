import React from "react";
import "./tab-nav.scss";
import { NavLink } from "react-router-dom";
import StyledText from "../StyledText";

type TabNavRoute = {
	to: string;
	label: string;
};

type TabNavProps = {
	routes: TabNavRoute[];
	extra?: any;
};

const TabNav = ({ routes, extra }: TabNavProps) => {
	return (
		<div className="tab-nav">
			<div className="tab-nav-items">
				{routes.map((item: TabNavRoute) => (
					<NavLink
						key={item.to + item.label}
						activeClassName="tab-nav-item-active"
						className="tab-nav-item"
						to={item.to}
					>
						<StyledText>{item.label}</StyledText>
					</NavLink>
				))}
			</div>
			{extra}
		</div>
	);
};

export default TabNav;
