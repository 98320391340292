import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import authReducer from "./auth";
import alertReducer from "./alert";
import LocationReducer from "./location";

const rootReducer = combineReducers({
	auth: authReducer,
	alert: alertReducer,
	location: LocationReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
