import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import store from "../redux";
import { updateProfile } from "../redux/auth";
import { useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

const usePaymentRedirect = ({
	fetchProfile,
	price,
	stripe,
	plan,
}: {
	fetchProfile?: boolean;
	price?: any;
	stripe: any;
	plan: string;
}) => {
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const auth = useSelector((state: any) => state.auth);
	let url = location.search;
	let params = queryString.parse(url) as any;

	useEffect(() => {
		const refetchProfile = () => {
			axios.get("/auth/profile").then(({ data }: any) => {
				if (data.data?.id) {
					store.dispatch(updateProfile(data.data));
					setLoading(false);
					setStatus("geslaagd");
				}
			});
		};
		const chargePayment = () => {
			if (!stripe || !params.source) return;
			stripe
				.createSource({
					type: "sepa_debit",
					sepa_debit: {
						ideal: params.source,
					},
					currency: "eur",
					owner: {
						name: auth.full_name,
					},
				})
				.then((result: any) => {
					console.log(result);
					axios
						.post(`/subscriptions/set-payment`, {
							source: result.source.id,
							price,
							plan,
						})
						.then(({ data }) => {
							setTimeout(() => {
								refetchProfile();
							}, 500);
						})
						.catch((e) => {
							setLoading(false);
							setStatus("failed");
						});
					// handle result.error or result.source
				});
		};

		if (params.source) {
			setLoading(true);
			setTimeout(() => {
				chargePayment();
			}, 3000);
		}
	}, [
		auth.full_name,
		fetchProfile,
		location.search,
		params.source,
		plan,
		price,
		stripe,
	]);

	return [loading, status, params];
};
export default usePaymentRedirect;
