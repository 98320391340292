import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckoutIban from "./CheckoutIban";
import Layout from "../../components/Layout";
import "./checkout-view.scss";
import usePaymentRedirect from "../../hooks/usePaymentRedirect";
import { useDispatch } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";
import StripeWrapper from "../../lib/StripeWrapper";
import Card from "../../components/Card";
import Button from "../../components/Button";
import usePatch from "../../hooks/rest/usePatch";
import { updateProfile } from "../../redux/auth";

const CheckoutView = () => {
	const stripe = useStripe() as any;
	const params = useParams() as any;
	const history = useHistory();
	const price = 0.5;
	const dispatch = useDispatch();
	const [setProfilePlan] = usePatch("/auth/profile");
	const [loading, status, data] = usePaymentRedirect({
		price,
		stripe,
		plan: params.plan,
	});

	let whitelist_wait = process.env.REACT_APP_USE_WHITELIST;
	useEffect(() => {
		if (whitelist_wait === "true") history.push("/signup");
	}, [history, whitelist_wait]);

	const setFreePlan = useCallback(() => {
		if (params.plan === "free") {
			setProfilePlan({ is_free: true }).then(({ data }) => {
				console.log(data);
				dispatch(updateProfile(data));
				history.push("/dashboard");
			});
		}
	}, [dispatch, history, params.plan, setProfilePlan]);

	useEffect(() => setFreePlan(), [setFreePlan]);

	if (params.plan === "free") {
		return <Layout loading={true}></Layout>;
	}

	if (data?.source) {
		return (
			<Layout loading={loading || false}>
				<section className="checkout-view checkout-view_result">
					<div className="container-small">
						<Card>
							{status === "geslaagd" ? (
								<>
									<h2 style={{ marginBottom: 10 }}>
										<i className="fal fa-check"></i> Uw
										betaling is gelukt!
									</h2>
									<div className="buttons full-center">
										<Button to="/login">Inloggen</Button>
									</div>
								</>
							) : (
								<>
									<div className="full-center">
										<h2 style={{ marginBottom: 10 }}>
											Uw betaling is mislukt
										</h2>
										<p>
											Neem contact op met{" "}
											<a href="mailto: support@easyzeats.nl">
												support@easyzeats.nl
											</a>
											.
										</p>
									</div>
								</>
							)}
						</Card>
					</div>
				</section>
			</Layout>
		);
	}

	return (
		<Layout>
			<section className="checkout-view">
				<div className="container-small">
					<h2>Afrekenen</h2>
					{(params?.plan === "monthly" ||
						params.plan === "yearly") && (
						<CheckoutIban {...{ price }} plan={params.plan} />
					)}
				</div>
			</section>
		</Layout>
	);
};

export default () => (
	<StripeWrapper>
		<CheckoutView />
	</StripeWrapper>
);
