import React from "react";
import "./person-slider.scss";
import Button from "../Button";
import Slider from "react-slick";

type PersonSliderProps = {
	max: number;
	onChange: (value: number) => void;
	value?: number;
	label: string;
};

const settings = {
	slidesToShow: 4,
	dots: false,
	infinite: false,
	responsive: [
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
	],
};

const PersonSlider = ({ max, onChange, value, label }: PersonSliderProps) => {
	let items = [];
	for (let i = 1; i <= max; i++) {
		items.push(i);
	}

	return (
		<div className="person-slider">
			<Slider {...settings} className="person-slider-items">
				{items.map((item) => (
					<Button
						onClick={() => onChange(item)}
						key={item}
						type={item === value ? "" : "outline"}
					>
						{item}
					</Button>
				))}
			</Slider>
		</div>
	);
};

export default PersonSlider;
