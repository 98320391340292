import React from "react";
import "./layout.scss";
import Nav from "./Nav/Nav";
import AdminNav from "./AdminNav";
import StyledText from "../StyledText";
import Footer from "../Footer";

type LayoutProps = {
	children?: any;
	type?: string;
	loading?: boolean;
	title?: string;
	error?: any;
	isSearch?: boolean;
	className?: string;
	isWhite?: boolean;
	noLine?: boolean;
	button?: any;
	footerName?: string;
	showButtonMobile?: boolean;
};

const Layout = ({
	children,
	type,
	title,
	loading,
	error,
	className,
	isSearch,
	isWhite,
	showButtonMobile,
	button,
	footerName,
	noLine,
}: LayoutProps) => {
	if (type === "admin") {
		return (
			<div className={`layout-admin ${noLine ? "layout-no-line" : ""}`}>
				<AdminNav title={title} />
				<main className={className || ""}>
					<div className="layout-head">
						{title && (
							<StyledText className="layout-title" type="title">
								{title}
							</StyledText>
						)}
						{button || false}
					</div>
					{button && showButtonMobile && (
						<div className="layout-mobile-button">{button}</div>
					)}

					{loading ? (
						<div className="layout-admin-loading">
							<i className="fal fa-spin fa-spinner-third"></i>
						</div>
					) : (
						children
					)}
				</main>
			</div>
		);
	}

	if (type !== "admin" && loading) {
		return (
			<>
				<Nav />
				<main className={`layout ${className || ""}`}>
					<div className="layout-loading">
						<i className="fal fa-spin fa-spinner-third"></i>
					</div>
				</main>
			</>
		);
	}

	return (
		<>
			<Nav {...{ isSearch, isWhite }} />
			<main className={`layout ${className || ""}`}>
				{error ? (
					<div className="layout-error">
						<StyledText type="title">{error}</StyledText>
					</div>
				) : (
					children
				)}
			</main>
			<Footer className={footerName} />
		</>
	);
};

export default Layout;
