import React, { useState, useEffect, useRef } from "react";
import "./reservations-view.scss";
import Layout from "../../../components/Layout";
import { useSelector } from "react-redux";
import useGet from "../../../hooks/rest/useGet";
import StyledText from "../../../components/StyledText";
import TabNav from "../../../components/TabNav";
import { useParams } from "react-router-dom";
import ReservationModal from "../../../components/ReservationModal";
import ReservationTable from "./ReservationTable";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import moment from "moment";
import ReservationDetailModal from "../../../components/ReservationDetailModal";
import PauseRestaurant from "../../../components/PauseRestaurant";
import { RefetchButton } from "../DashboardView";
import FilterModal from "./FilterModal";

export const RESERVATION_ROUTES = [
	{
		to: "/reservations/today",
		label: "Vandaag",
	},
	{
		to: "/reservations/future",
		label: "Vooruitzicht",
	},
	{
		to: "/reservations/pause/timeslot",
		label: "Dichtzetten",
	},
];

function objectToQueryString(obj: { [key: string]: any }) {
	var str = [];
	for (var p in obj)
		if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return str.join("&");
}

const ReservationsView = () => {
	const { type } = useParams() as any;
	const [mounted, setMounted] = useState(false);
	const [params, setParams] = useState({
		date: moment()
			.add(type === "future" ? 1 : 0, "days")
			.format("YYYY-MM-DD"),
	}) as any;
	const [filterModalVisible, setFilterModalVisible] = useState(false);
	const [selected, setSelected] = useState({
		id: null,
	});
	const [visible, setVisible] = useState(false);
	const [filter, setFilter] = useState("");
	let query = objectToQueryString(params || {});
	const [result, loading, , refetch] = useGet(`/reservations`, query);
	let data = result.data || [];

	const handleDelete = () => {
		refetch();
	};

	useEffect(() => {
		if (type === "future" && mounted) {
			setParams((params: any) => ({
				...params,
				date: moment().add(1, "days").format("YYYY-MM-DD"),
			}));
		} else if (mounted) {
			setParams((params: any) => ({
				...params,
				date: moment().format("YYYY-MM-DD"),
			}));
		}
		setMounted(true);
	}, [mounted, setParams, type]);

	return (
		<>
			<ReservationDetailModal
				onClose={() => setSelected({ id: null })}
				reservation={selected}
			/>
			<ReservationModal
				visible={visible}
				onClose={() => setVisible(false)}
			/>
			<Layout
				noLine
				type="admin"
				className="reservations-view"
				title="Reserveringen"
				button={<PauseRestaurant />}
				showButtonMobile
			>
				<TabNav routes={RESERVATION_ROUTES} />
				<div className={`reservations-view-head `}>
					<Button onClick={() => setVisible(true)}>Toevoegen</Button>
					<RefetchButton
						className="reload-button"
						onClick={refetch}
					/>
					<FilterModal
						visible={filterModalVisible}
						setVisible={setFilterModalVisible}
						{...{ params, setParams }}
					/>

					{data.length ? (
						<Input
							placeholder="Zoek op naam, nummer of email"
							onChange={(val) => setFilter(val)}
							value={filter}
						/>
					) : (
						false
					)}
					{type === "future" && (
						<Input
							className="date-input"
							date
							placeholder="Datum"
							onChange={(value) =>
								setParams({ ...params, date: value })
							}
							value={params.date}
						/>
					)}
				</div>
				<ReservationTable
					{...{ data, loading, query: filter }}
					onDelete={handleDelete}
					refetch={refetch}
					onShow={setSelected}
				/>
			</Layout>
		</>
	);
};

type ReservationListProps = {
	timeslot: {
		id: number;
	};
};

export default ReservationsView;
