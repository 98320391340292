import React, { useState, useRef } from "react";
import "./timeslot-picker.scss";
import Card from "../Card";
import StyledText from "../StyledText";
import TimeslotForm from "./TimeslotForm";
import { useSelector } from "react-redux";
import TimeslotList from "../TimeslotList";

type TimeslotPicker = {
	showRegistrations?: boolean;
	restaurantId?: number;
	isProfile?: boolean;
	form?: (timeslot: any, refetch: any) => any;
};

const TimeslotPicker = ({ restaurantId, form, isProfile }: TimeslotPicker) => {
	const auth = useSelector((state: any) => state.auth);
	restaurantId = restaurantId ? restaurantId : auth.restaurant.id;
	const is_mine = auth.restaurant.id === restaurantId;
	const formRef = useRef(null);
	const timeslotListRef = useRef(null) as any;
	const [selected, setSelected] = useState({ id: null });

	const refetch = () => {
		if (timeslotListRef && timeslotListRef.current) {
			timeslotListRef.current.fetch();
		}
	};

	return (
		<div
			className={`timeslot-picker ${
				selected && selected.id ? "timeslot-picker-has-timeslot" : ""
			}`}
		>
			<Card className="timeslot-picker-box">
				<TimeslotList
					isAdmin
					ref={timeslotListRef}
					selected={selected}
					onSelect={(item) => {
						// scrollToRef(formRef);
						setSelected(item);
					}}
					showButton={is_mine && !isProfile}
					{...{ restaurantId }}
				/>
			</Card>
			<div
				ref={formRef}
				className="timeslot-picker-box timeslot-picker-box-form"
			>
				{selected && selected.id ? (
					<Card>
						{form !== undefined ? (
							<div className="timeslot-form">
								{form(selected, refetch)}
							</div>
						) : (
							<TimeslotForm
								isAdmin
								refetch={refetch}
								onClear={() => {
									setSelected({ id: null });
									refetch();
								}}
								timeslot={selected}
								restaurantId={restaurantId}
							/>
						)}
					</Card>
				) : (
					<Card>
						<div className="timeslot-form-empty">
							<i className="fal fa-clock"></i>
							<StyledText>Kies eerst een tijd</StyledText>
						</div>
					</Card>
				)}
			</div>
		</div>
	);
};

export default TimeslotPicker;
