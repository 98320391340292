import React from "react";
import "./switch.scss";
import StyledText from "../StyledText";
type SwitchProps = {
	onChange: (val: boolean) => void;
	value: boolean;
	label: string;
};

const Switch = ({ onChange, value, label }: SwitchProps) => {
	return (
		<div className="switch-wrapper">
			<StyledText className="switch-label">{label}</StyledText>
			<div onClick={() => onChange(!value)} className="switch">
				<span className={`switch-item ${value ? "switch-active" : ""}`}>
					Aan
				</span>
				<span
					className={`switch-item switch-off ${
						!value ? "switch-active" : ""
					}`}
				>
					Uit
				</span>
			</div>
		</div>
	);
};

export default Switch;
