import React, { useState, useEffect } from "react";
import "./timeslot-picker-v2.scss";
import TimeslotListV2 from "./TimeslotListV2";
import Button from "../Button";
import Form from "./Form";
import { useSelector } from "react-redux";
import useGet from "../../hooks/rest/useGet";
import Modal from "../Modal";

import weeks from "../../assets/images/weeks.jpg";
import calendar from "../../assets/images/calendar.jpg";
import StyledText from "../StyledText";
import { useLocation } from "react-router-dom";

const TimeslotPickerV2 = () => {
	const auth = useSelector((state: any) => state.auth);
	const [visible, setVisible] = useState(false);
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	const [result, loading, , refetch] = useGet(
		`/restaurants/${restaurant.id}/timeslots?all=1`
	);
	const hide_button = auth.is_free && result?.data?.length === 3;
	const location = useLocation();
	useEffect(() => {
		setVisible(false);
	}, [location]);

	return (
		<>
			<Modal
				title="Nieuw tijdvak maken"
				className="tp2-choice-modal"
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<div className="choice-row">
					<div className="col">
						<img src={weeks} alt="" />
						<StyledText>
							Voeg tijdvakken toe en stel het aantal stoelen in op
							basis van weekdagen.
						</StyledText>
						<Button to="/account/timeslots/create/days">
							Weekdagen
						</Button>
					</div>
					<div className="col">
						<img src={calendar} alt="" />
						<StyledText>
							Voeg tijdvakken toe en stel het aantal stoelen in
							voor een specifieke datum of datum reeks.
						</StyledText>
						<Button to="/account/timeslots/create/date">
							Datum
						</Button>
					</div>
				</div>
			</Modal>
			<div className="tp2-button">
				{!loading && !hide_button && (
					<Button onClick={() => setVisible(true)}>
						Tijdvak aanmaken
					</Button>
				)}
			</div>
			<div className="tp2">
				<TimeslotListV2 {...{ result, loading }} />
				<div className="tp2-body">
					<Form refetch={refetch} />
				</div>
			</div>
		</>
	);
};

export default TimeslotPickerV2;
