import React from "react";
import "./logo.scss";
import { NavLink } from "react-router-dom";

const Logo = ({
	isPrimary,
	type,
	to,
}: {
	isPrimary?: boolean;
	type?: string;
	to?: string;
}) => {
	let source = null;
	if (type === "circle") {
		source = require("../../assets/images/logo-circle.svg");
	} else if (type === "circle-white") {
		source = require("../../assets/images/logo-round-white.png");
	} else if (type === "circle-white-stacked") {
		source = require("../../assets/images/logo-round-white-stacked.png");
	} else if (type === "gradient") {
		source = require("../../assets/images/logo-gradient.png");
	} else if (type === "green-normal") {
		source = require("../../assets/images/logo-green.png");
	} else {
		source = require("../../assets/images/logo-text.svg");
	}

	if (to) {
		return (
			<NavLink to={to}>
				<img src={source} alt="" className="logo" />
			</NavLink>
		);
	}

	return <img src={source} alt="" className="logo" />;
};

export default Logo;
