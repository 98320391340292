import React from "react";
import "./for-partners-view.scss";
import Layout from "../../../components/Layout";
import Button from "../../../components/Button";
import TitleBox from "../../../components/TitleBox";

const ForPartnersView = () => {
	return (
		<Layout isWhite>
			<div className="container">
				<Header />
			</div>
			<Checklist />
		</Layout>
	);
};

const Header = () => (
	<header className="for-partners-header">
		<TitleBox
			title="Als het maar goed en simpel werkt"
			text="Snel en gemakkelijk tijdvakken en stoelen beschikbaar maken?
			Het is nu mogelijk en in een handomdraai geregeld."
			button={
				<Button noUppercase type="secondary" to="/signup">
					Gratis proberen
				</Button>
			}
		/>

		<div className="for-partners-header-image">
			<img
				src={require("../../../assets/images/intro.png")}
				alt="Overview"
			/>
		</div>
	</header>
);

const Checklist = () => (
	<div className="for-partners-checklist">
		<div className="container">
			<div className="for-partners-checklist-image">
				<img
					src={require("../../../assets/images/demo-partnerworden.png")}
					alt="Card"
				/>
			</div>
			<div className="for-partners-checklist-info">
				<ul>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>
							Makkelijk en snel geregistreerd & twee maanden
							gratis proberen.
						</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>
							Tijdvakken & stoelen in een handomdraai ingesteld.
						</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>Live beschikbaarheid van je stoelen.</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>Beheer eenvoudig je reserveringen.</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>Duidelijk overzicht van je reserveringen.</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>Een vaste lage prijs & geen extra kosten.</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>
							Samen sterk: Maak jouw abonnement gratis.
							<span className="description">
								Zorg dat jouw horeca collega’s ook partner
								worden! Krijg €2,50 korting voor elke horeca
								onderneming net zo lang totdat jouw abonnement
								gratis is.
							</span>
						</p>
					</li>
					<li>
						<i className="fal fa-check-circle"></i>
						<p>
							Makkelijk te gebruiken op mobiel, tablet & computer.
						</p>
					</li>
				</ul>
				<div className="buttons">
					<Button noUppercase type="secondary" to="/signup">
						Gratis proberen
					</Button>
				</div>
			</div>
		</div>
	</div>
);

export default ForPartnersView;
