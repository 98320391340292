import React from "react";
import Days from "./Days";
import StyledText from "../StyledText";
import moment from "moment";
import { NavLink } from "react-router-dom";

export type TimeslotProps = {
	id: number;
	days_indexs: number[];
	text?: string;
	date: {
		start: string | null;
		end: string | null;
	};
	time: {
		start: string | null;
		end: string | null;
	};
	capacity: number;
	remaining: number;
	is_full: boolean;
	comment: string | null;
	onClick?: () => void;
	active?: boolean;
};

const TimeslotButton = ({
	id,
	days_indexs,
	time,
	capacity,
	date,
	text,
	onClick,
	active,
}: TimeslotProps) => {
	const renderHead = () => {
		if (text) {
			return (
				<div className="timeslot-button-date">
					<div className="icon">
						<img
							src={require("../../assets/images/icons/calendar.svg")}
							alt=""
						/>
					</div>
					<StyledText>{text}</StyledText>
				</div>
			);
		}

		if (date.start && date.end) {
			return renderDate();
		}
		return <Days activeIndexs={days_indexs} />;
	};

	const renderDate = () => {
		return (
			<div className="timeslot-button-date">
				<div className="icon">
					<img
						src={require("../../assets/images/icons/calendar.svg")}
						alt=""
					/>
				</div>
				<StyledText>
					{moment(date.start, "DD-MM-YYYY").format("D MMM YYYY")} -{" "}
					{moment(date.end, "DD-MM-YYYY").format("D MMM YYYY")}
				</StyledText>
			</div>
		);
	};

	if (onClick) {
		return (
			<div
				onClick={onClick}
				className={`timeslot-button ${
					active ? "timeslot-button-active" : ""
				}`}
			>
				{renderHead()}
				<div className="timeslot-button-info">
					<StyledText>
						{time.start} - {time.end}
					</StyledText>
					<StyledText>{capacity} stoelen</StyledText>
				</div>
			</div>
		);
	}

	return (
		<NavLink
			activeClassName="timeslot-button-active"
			to={`/account/timeslots/${id}`}
			className="timeslot-button"
		>
			{renderHead()}
			<div className="timeslot-button-info">
				<StyledText>
					{time.start} - {time.end}
				</StyledText>
				<StyledText>{capacity} stoelen</StyledText>
			</div>
		</NavLink>
	);
};

export default TimeslotButton;
