import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import useGet from "../../hooks/rest/useGet";
import Banner from "../../components/Banner";
import Card from "../../components/Card";
import Button from "../../components/Button";
import useDelete from "../../hooks/rest/useDelete";
import useAlert from "../../hooks/useAlert";
import moment from "moment";
import StyledText from "../../components/StyledText";
import "./customer-reservation-view.scss";
import PersonSlider from "../../components/PersonSlider";
import usePatch from "../../hooks/rest/usePatch";

const CustomerReservationView = () => {
	const { hid } = useParams() as any;
	const [result, loading, error, , setResult] = useGet(`/reservation/${hid}`);
	const [onCancel, loadingCancel] = useDelete(`/reservation/${hid}`);
	const [isCanceled, setCanceled] = useState(false);
	const [sendAlert] = useAlert();
	const reservation = result?.data || {};
	let restaurant = reservation.timeslot?.restaurant || {};
	const [persons, setPersons] = useState(reservation.persons || 0);
	const [updateReservation, saving] = usePatch(`/reservation/${hid}`);
	useEffect(() => {
		if (result?.data?.persons) {
			setPersons(result.data.persons);
		}
	}, [setPersons, result]);

	if (!loading && error) {
		return (
			<Layout>
				<Banner title="Uw reservering is niet gevonden, verlopen of geannuleerd" />
			</Layout>
		);
	}

	if (isCanceled) {
		return (
			<Layout>
				<Banner title="Uw reservering is geanuleerd" />
			</Layout>
		);
	}

	const timeslot = result?.data?.timeslot || {};
	console.log(timeslot);
	if (!timeslot) {
		return (
			<Layout>
				<Banner title="Oeps er is iets misgegaan" />
			</Layout>
		);
	}

	const day = moment(timeslot?.dates?.start).format("dddd");
	const month = moment(timeslot?.dates?.start).format("MMMM");
	const max_persons = timeslot.remaining + reservation.persons;

	const handleSave = () => {
		updateReservation({ persons }).then(() => {
			let text = `Uw reservering is aangepast van ${
				reservation.persons
			} naar ${persons} ${persons === 1 ? "plek" : "plekken"}`;
			setResult({
				...result,
				data: {
					...result.data,
					persons,
				},
			});
			sendAlert({
				type: "success",
				title: `Succesvol aangepast`,
				text,
			});
		});
	};

	return (
		<Layout className="customer-reservation-view" loading={loading}>
			<Banner title={`Uw reservering bij ${restaurant.name || ""}`}>
				<Card>
					<StyledText className="description">
						U heeft gereserveerd om {timeslot?.time?.start} op {day}{" "}
						{timeslot.day}{" "}
						{moment(timeslot?.dates?.start).format("D")} {month}{" "}
						voor {reservation.persons}{" "}
						{reservation.persons === 1 ? "persoon" : "personen"}
					</StyledText>

					<PersonSlider
						max={max_persons >= 10 ? 10 : max_persons}
						onChange={setPersons}
						value={persons}
						label="Aantal personen aanpassen"
					/>

					<div className="buttons">
						<Button
							type="gray"
							loading={loadingCancel}
							onClick={() => {
								sendAlert({
									title: "Reservering annuleren",
									text:
										"Weet u zeker dat u deze reservering wilt annuleren",
									type: "confirm",
									onConfirm: () =>
										onCancel().then(() =>
											setCanceled(true)
										),
								});
							}}
						>
							Reservering annuleren
						</Button>
						<Button
							loading={saving}
							disabled={reservation.persons === persons}
							onClick={handleSave}
						>
							Aanpassen
						</Button>
					</div>
				</Card>
			</Banner>
		</Layout>
	);
};

export default CustomerReservationView;
