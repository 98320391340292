import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import usePost from "../../hooks/rest/usePost";
import { updateProfile } from "../../redux/auth";
import { Packages } from "../Info/SubscriptionsView";
import Package from "../Info/SubscriptionsView/Package";

const ChangeSubscription = () => {
	const auth = useSelector((state: any) => state.auth);
	const dispatch = useDispatch();
	const history = useHistory();
	const [changeSub, loading] = usePost("/subscriptions/swap");
	const [visible, setVisible] = useState(false);
	let current_type = "";

	if (auth.is_free) current_type = "free";

	if (auth.current_subscription)
		current_type = auth.current_subscription.type;

	const handleChange = (type: string) => {
		if (type !== "free") {
			history.push(`/checkout/${type}`);
			return;
		}
		changeSub({ plan: type }).then(({ data }) => {
			dispatch(updateProfile(data));
			setVisible(false);
		});
	};

	return (
		<>
			<Modal
				title="Abbonnement wijzigen"
				{...{ visible, loading }}
				onClose={() => setVisible(false)}
			>
				<div className="packages">
					<Package
						title="Van het huis"
						description="Eenvoudig bereikbaar en een unieke online ervaring van jouw restaurant"
						prices={{
							monthly: 0,
							yearly: 0,
						}}
						button={{
							label:
								current_type === "free"
									? "geselecteerd"
									: "Kiezen",
							disabled: current_type === "free",
							onClick: () => handleChange("free"),
						}}
					/>
					<Package
						title="Specialiteit"
						description="Geautomatiseerd en eenvoudig reserveringsplatform"
						prices={{
							monthly: 39,
							yearly: 365,
						}}
						button={{
							label:
								current_type === "monthly"
									? "geselecteerd"
									: "Kiezen",
							disabled: current_type === "monthly",
							onClick: () => handleChange("monthly"),
						}}
						isMonthly={true}
					/>
					<Package
						title="Specialiteit"
						description="Geautomatiseerd en eenvoudig reserveringsplatform"
						prices={{
							monthly: 39,
							yearly: 365,
						}}
						button={{
							label:
								current_type === "yearly"
									? "geselecteerd"
									: "Kiezen",
							disabled: current_type === "yearly",
							onClick: () => handleChange("yearly"),
						}}
						isMonthly={false}
					/>
				</div>
			</Modal>
			<Button loading={loading} onClick={() => setVisible(true)}>
				Wijzigen
			</Button>
		</>
	);
};

export default ChangeSubscription;
