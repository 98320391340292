import React, { useState, useEffect } from "react";
import { useParams, NavLink, useHistory } from "react-router-dom";
import Button from "../Button";
import Card from "../Card";
import StyledText from "../StyledText";
import Dropdown from "../Dropdown";
import Days from "./Days";
import Input from "../Input";
import usePost from "../../hooks/rest/usePost";
import usePatch from "../../hooks/rest/usePatch";
import useAlert from "../../hooks/useAlert";
import moment from "moment";
import useDelete from "../../hooks/rest/useDelete";
import { useSelector } from "react-redux";
import useGet from "../../hooks/rest/useGet";
import Loading from "../Loading";

const EMPTY_TIMESLOT = {
	date: {
		start: "",
		end: "",
	},
	time: {
		start: "",
		end: "",
	},
	comment: "",
	capacity: "",
	days: [],
};

const Form = ({ refetch }: { refetch?: () => any }) => {
	const auth = useSelector((state: any) => state.auth);
	const { id, type: routeType } = useParams() as any;
	const [saved, setSaved] = useState(false);
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	const [deleteTimeslot, loadingDelete] = useDelete(
		`/restaurants/${restaurant.id}/timeslots/${id}`
	);
	const [result, resultLoading] = useGet(
		id ? `/restaurants/${restaurant.id}/timeslots/${id}` : undefined
	);
	const [patchTimeslot, loadingPatch] = usePatch(
		`/restaurants/${restaurant.id}/timeslots/${id}`
	);
	const history = useHistory();
	const [sendAlert] = useAlert();
	const [createTimeslot, loading] = usePost(`/restaurants/my/timeslots`);
	const [type, setType] = useState(routeType || "days");
	const [timeslot, setTimeslot] = useState({
		...EMPTY_TIMESLOT,
	} as any);

	useEffect(() => {
		setType(routeType || "days");
	}, [routeType]);

	useEffect(() => {
		if (id && result.data) {
			let t = result.data || {};
			if (t.date.start && t.date.end) {
				setType("date");
			} else {
				setType("days");
			}
			setTimeslot({
				capacity: t.capacity,
				date: {
					start: t.date.start
						? moment(t.date.start, "DD-MM-YYYY").format(
								"YYYY-MM-DD"
						  )
						: "",
					end: t.date.end
						? moment(t.date.end, "DD-MM-YYYY").format("YYYY-MM-DD")
						: "",
				},
				time: {
					start: t.time.start,
					end: t.time.end,
				},
				comment: t.comment,
				days: t.days_indexs,
			});
		}
	}, [result, id]);

	const clear = () => {
		setTimeslot({ ...EMPTY_TIMESLOT });
	};

	const handleSubmit = () => {
		setSaved(true);
		let { time, date, days, capacity, comment } = timeslot;
		if (date.start && date.end) {
			if (
				moment(date.start).unix() > moment(date.end).unix() &&
				!days.length
			) {
				// sendAlert({
				// 	type: "error",
				// 	title: "Oeps!",
				// 	text: "Controleer uw start en einddatum",
				// });
				return;
			}
		}

		if (!capacity || !time.start || !time.end) {
			sendAlert({
				type: "error",
				title: "Oeps!",
				text: "Controleer of u de velden heeft ingevuld",
			});
			return;
		}
		let data = {
			days: days.length ? days : null,
			capacity: Number(capacity),
			start_time: moment(time.start).format("HH:mm"),
			end_time: moment(time.end).format("HH:mm"),
			comment,
			start_date: null,
			end_date: null,
		};

		setSaved(false);

		if (id) {
			if (date.start && date.end) {
				data.start_date =
					date.start !== result.data.start_date ? date.start : null;
				data.end_date =
					date.end !== result.data.end_date ? date.end : null;
			}

			patchTimeslot(data)
				.then(() => {
					sendAlert({
						type: "success",
						title: "Uw tijdvak is aangepast",
					});

					history.push("/account/timeslots");
				})
				.catch(handleError);
		} else {
			if (date.start && date.end) {
				data.start_date = date.start;
				data.end_date = date.end;
			}

			console.log(data);
			createTimeslot(data)
				.then(() => {
					sendAlert({
						type: "success",
						title: "Uw tijdvak is aangemaakt",
					});
					history.push("/account/timeslots");
					if (refetch) {
						refetch();
					}

					clear();
				})
				.catch(handleError);
		}
	};

	const handleError = (e: any) => {
		const errors = e?.response?.data.errors;
		sendAlert({
			type: "error",
			text: errors?.already_filled
				? "Er is al een tijdvak op deze dag + tijd"
				: "Probeer het opnieuw",
			title: "Er is iets misgegaan",
		});
	};

	const handleDelete = () => {
		sendAlert({
			type: "confirm",
			title: "Weet u zeker dat u dit tijdsvak wilt verwijderen",
			text: "Alle reserveringen voor dit tijdsvakken zullen verdwijnen",
			showTimeslotDropdown: true,
			filterId: id,
			onConfirm: (timeslot) =>
				deleteTimeslot(timeslot)
					.then(() => {
						history.push("/account/timeslots");
					})
					.catch(() => {
						sendAlert({
							type: "error",
							title: "Er is iets misgegaan",
						});
					}),
		});
	};

	const handleChange = (key: string, value: any) => {
		setTimeslot({
			...timeslot,
			[key]: value,
		});
	};

	if (id && resultLoading) {
		return <Loading />;
	}

	if (!routeType && !id) {
		return <div className="tp2-form"></div>;
	}

	return (
		<Card className="tp2-form">
			<div className="tp2-form-head">
				<StyledText type="title">
					<Button
						className="back"
						to="/account/timeslots"
						type="transparent"
						isCancel
					>
						<span></span>
					</Button>
				</StyledText>
				<div className="buttons">
					{id && (
						<Button
							onClick={handleDelete}
							loading={loadingDelete}
							isDelete
							type="gray"
						/>
					)}
					<Button
						isSave
						type="gray"
						onClick={handleSubmit}
						loading={loading || loadingPatch}
					/>
				</div>
			</div>
			<div className="tp2-form-type">
				<StyledText className="tp2-form-title">Type</StyledText>
				<div className="row type-row">
					<Dropdown
						data={[
							{ value: "days", label: "Weekdagen" },
							{ value: "date", label: "Datum" },
						]}
						value={type}
						onChange={(t) => {
							setType(t);
							if (t === "days")
								setTimeslot({ ...timeslot, date: {} });
							if (t === "date")
								setTimeslot({ ...timeslot, days: [] });
						}}
					/>
					<div className="type">
						{type === "days" ? (
							<Days
								type="primary"
								onChange={(days) => handleChange("days", days)}
								activeIndexs={timeslot.days}
							/>
						) : (
							<div className="date-row row">
								<Input
									label="van"
									showLabel={true}
									value={timeslot?.date?.start}
									onChange={(start) => {
										handleChange("date", {
											...timeslot.date,
											start,
										});
									}}
									date
									isRequired={
										saved &&
										!timeslot?.date?.start &&
										type !== "days"
									}
								/>
								<Input
									label="tot"
									showLabel={true}
									value={timeslot?.date?.end}
									onChange={(end) =>
										handleChange("date", {
											...timeslot.date,
											end,
										})
									}
									date
									isRequired={
										saved &&
										!timeslot?.date?.end &&
										type !== "days"
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="item">
					<StyledText className="tp2-form-title">Tijdvak</StyledText>
					<div className="date-row row">
						<Input
							label="van"
							showLabel
							value={timeslot.time.start}
							onChange={(start) => {
								handleChange("time", {
									...timeslot.time,
									start,
								});
							}}
							onBlur={() => {
								let { end, start } = timeslot.time;
								const beginningTime = moment(start, "HH:mm");
								const endTime = moment(end, "HH:mm");
								if (endTime.isBefore(beginningTime)) {
									sendAlert({
										type: "error",
										title:
											"Start tijden mogen nooit na de eindtijd zijn",
									});
									handleChange("time", {
										...timeslot.time,
										start: "",
									});
								}
							}}
							time
							isRequired={saved && !timeslot.time.start}
						/>
						<Input
							label="tot"
							showLabel
							value={timeslot.time.end}
							onChange={(end) =>
								handleChange("time", { ...timeslot.time, end })
							}
							onBlur={() => {
								let { end, start } = timeslot.time;
								const beginningTime = moment(start, "HH:mm");
								const endTime = moment(end, "HH:mm");
								if (endTime.isBefore(beginningTime)) {
									sendAlert({
										type: "error",
										title:
											"Eind tijden mogen nooit voor de begintijd zijn",
									});
									handleChange("time", {
										...timeslot.time,
										end: "",
									});
								}
							}}
							isRequired={saved && !timeslot.time.end}
							time
						/>
					</div>
				</div>
				<div className="item">
					<StyledText className="tp2-form-title">Stoelen</StyledText>
					<Input
						type="number"
						value={timeslot.capacity}
						max={auth.is_free ? 5 : undefined}
						placeholder="0"
						isRequired={saved && !timeslot.capacity}
						increments
						onChange={(val) => handleChange("capacity", val)}
					/>
				</div>
			</div>
			<div className="item">
				<StyledText className="tp2-form-title">Notitie</StyledText>
				<Input
					multiline
					placeholder="Notities zijn optioneel"
					value={timeslot.comment}
					onChange={(comment) => handleChange("comment", comment)}
				/>
			</div>
		</Card>
	);
};

export default Form;
