import React, { useState } from "react";
import "./search-box.scss";
import { useHistory } from "react-router-dom";
import StyledText from "../StyledText";
import Button from "../Button";
import SearchInputV2 from "./SearchInputV2";

const SearchBox = ({
	title,
	onClose,
}: {
	title?: string;
	onClose?: () => void;
}) => {
	const history = useHistory();
	const [type] = useState("all");
	const [location, setLocation] = useState({
		lat: "",
		lng: "",
	} as any);

	const onSubmit = (e?: any) => {
		if (e) {
			e.preventDefault();
		}
		let to = `/restaurants/${type}`;
		if (location.lat && location.lng) {
			to += `/${location.lat},${location.lng}`;
		}
		history.push(to);
	};

	return (
		<div className="search">
			{/* {title && <StyledText type="title">{title}</StyledText>} */}
			<form onSubmit={onSubmit} className="search-box">
				<div className="search-box-input">
					<SearchInputV2
						onSubmit={(geo) => {
							let to = `/restaurants/${type}`;
							if (geo.lat && geo.lng) {
								to += `/${geo.lat},${geo.lng}`;
							}
							history.push(to);
							if (onClose) {
								onClose();
							}
						}}
						{...{ location, setLocation }}
					/>
				</div>
				<Button onClick={onSubmit}>Zoeken</Button>
			</form>
		</div>
	);
};

export default SearchBox;
