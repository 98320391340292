import axios from "axios";

const API_URL = process.env.REACT_APP_API_URl;
axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(async (config) => {
	let token: string | null = localStorage.getItem("token");
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

export default axios;
