import React, { useState } from "react";
import "./register-view.scss";
import { useHistory, NavLink } from "react-router-dom";
import Card from "../../../components/Card";
import StyledText from "../../../components/StyledText";
import Input from "../../../components/Input";
// import CheckBox from "../../../components/CheckBox";
import Button from "../../../components/Button";
import usePost from "../../../hooks/rest/usePost";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../redux/auth";
import CategoryDropdown from "../../../components/Dropdown/CategoryDropdown";
import useAlert from "../../../hooks/useAlert";
import Loading from "../../../components/Loading";
import Logo from "../../../components/Logo";

const RegisterView = () => {
	const [sendAlert] = useAlert();
	const [saved, setSaved] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const [registerRestaurant, loading] = usePost("/auth/signup");
	// const [agreed, setAgreed] = useState(false);
	const [step, setStep] = useState(1);
	const [restaurant, setRestaurant] = useState({
		name: "",
		street: "",
		house_number: "",
		zipcode: "",
		city: "",
		description: "",
		category_id: undefined,
	} as any);

	const [user, setUser] = useState({
		first_name: "",
		affix: "",
		last_name: "",
		email: "",
		telephone: "",
		password: "",
		password_repeat: "",
	});

	const handleRestaurantChange = (key: string, value: any) => {
		setRestaurant({
			...restaurant,
			[key]: value,
		});
	};

	const handleUserChange = (key: string, value: any) => {
		setUser({
			...user,
			[key]: value,
		});
	};

	const handleSave = () => {
		setSaved(true);
		// if (!agreed) {
		// 	sendAlert({
		// 		type: "error",
		// 		title: "Er is wat misgegaan",
		// 		text:
		// 			"U moet de voorwaarden accepteren om te kunnen aanmelden.",
		// 	});
		// 	return;
		// }
		if (
			!user.first_name ||
			!user.last_name ||
			!user.email ||
			!user.password ||
			!user.password_repeat
		) {
			sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Vul alle verplichte account gegevens in",
			});
			setStep(1);
			return;
		}
		if (user.password !== user.password_repeat) {
			sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Wachtwoorden komen niet overeen",
			});
			setStep(1);
			return;
		}
		if (
			!restaurant.name ||
			!restaurant.category_id ||
			!restaurant.street ||
			!restaurant.house_number ||
			!restaurant.zipcode ||
			!restaurant.street
		) {
			sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Vul alle verplichte restaurant velden in",
			});
			setStep(2);

			return;
		}

		if (user.password.length < 6) {
			sendAlert({
				type: "error",
				title: "Wachtwoord te kort",
				text: "Uw wachtwoord moet minimaal 6 karakters hebben",
			});
			return;
		}

		delete user.password_repeat;
		registerRestaurant({
			...user,
			restaurant,
		})
			.then(({ data }: any) => {
				setSaved(false);
				dispatch(updateProfile(data));
				history.push("/dashboard");
			})
			.catch((e) => {
				let errors = e?.response?.data?.errors;
				if (errors?.email) {
					sendAlert({
						type: "error",
						title: "Er is al een account met uw email",
					});
				} else {
					sendAlert({
						type: "error",
						title: "Er is iets misgegaan",
					});
				}
			});
	};

	return (
		<div className="register-view">
			<div className="container-small">
				{loading ? (
					<div className="register-view-loading">
						<Loading />
					</div>
				) : (
					<div className="register-grid">
						<Card type="no-shadow">
							<div className="login-view-logo">
								<Logo to="/" type="circle-white-stacked" />
							</div>
							<StyledText className="register-grid-title">
								Maak een gratis partner account aan
							</StyledText>

							<div className="form">
								<div className="pagination">
									<p>Stap {step} van 2</p>
								</div>
								<div className="form-fields">
									{step === 1 && (
										<StepOne
											{...{ restaurant, user }}
											saved={saved}
											onChange={handleUserChange}
										/>
									)}
									{step === 2 && (
										<StepTwo
											{...{ restaurant, user }}
											saved={saved}
											onChange={handleRestaurantChange}
										/>
									)}
								</div>
								<div className="buttons">
									{step === 2 ? (
										<Button
											onClick={() => setStep(1)}
											icon="fas fa-arrow-left"
											type="transparent"
										>
											Terug
										</Button>
									) : (
										<span />
									)}
									{step === 1 ? (
										<Button
											onClick={() => {
												if (
													!user.first_name ||
													!user.last_name ||
													!user.password ||
													!user.password_repeat
												) {
													sendAlert({
														type: "error",
														title:
															"Vul alle verplichte velden in",
													});
													return;
												} else {
													setStep(2);
												}
											}}
											type="transparent"
											iconPosition="right"
											icon="fas fa-arrow-right"
										>
											Volgende stap
										</Button>
									) : (
										<Button
											loading={loading}
											onClick={handleSave}
										>
											Aanmaken
										</Button>
									)}
								</div>
								{step === 2 && (
									<div className="agree-cta">
										<StyledText>
											Door een account aan te maken gaat u
											automatisch akkoord met de{" "}
											<NavLink to="/terms-and-conditions">
												algemene voorwaarden.
											</NavLink>
										</StyledText>
									</div>
								)}
								<div className="login-cta">
									<StyledText>
										Ik heb al een partner account
									</StyledText>
									<Button type="transparent" to="/login">
										Inloggen
									</Button>
								</div>
							</div>
						</Card>
					</div>
				)}
			</div>
		</div>
	);
};

// CheckBox
// 								onChange={setAgreed}
// 								value={agreed}
// 								label={
// 									<StyledText>
// 										Ja, ik ga akkoord met de{" "}
// 										<NavLink to="/privacy-policy">
// 											privacy verklaring
// 										</NavLink>
// 									</StyledText>
// 								}
// 							/>
// 							<StyledText className="disclaimer">
// 								Wij gaan zorgvuldig om met je persoonsgegevens.
// 								Bij het aanmaken van een account ga je akkoord
// 								met onze{" "}
// 								<NavLink to="/terms-and-conditions">
// 									Algemene voorwaarden
// 								</NavLink>
// 							</StyledText>

type StepProps = {
	onChange: (key: string, value: any) => void;
	restaurant?: any;
	user?: any;
	saved?: any;
};

const StepOne = ({ onChange, restaurant, user, saved }: StepProps) => (
	<>
		<div className="row">
			<Input
				value={user.first_name}
				onChange={(text) => onChange("first_name", text)}
				type="text"
				placeholder="Voornaam"
				label="Voornaam"
				name="first_name"
				className="full"
				isRequired={saved && !user.first_name}
			/>

			<Input
				value={user.last_name}
				onChange={(text) => onChange("last_name", text)}
				type="text"
				placeholder="Achternaam"
				label="Achternaam"
				name="last_name"
				className="full"
				isRequired={saved && !user.last_name}
			/>
		</div>

		<Input
			value={user.email}
			onChange={(text) => onChange("email", text)}
			type="email"
			placeholder="Emailadres"
			label="Emailadres"
			name="email"
			isRequired={saved && !user.email}
		/>
		<Input
			value={user.password}
			onChange={(text) => onChange("password", text)}
			type="password"
			placeholder="Wachtwoord"
			label="Wachtwoord"
			isRequired={saved && !user.password}
		/>
		<Input
			value={user.password_repeat}
			onChange={(text) => onChange("password_repeat", text)}
			type="password"
			placeholder="Wachtwoord herhalen"
			label="Wachtwoord herhalen"
			isRequired={saved && !user.password_repeat}
		/>
	</>
);

const StepTwo = ({ onChange, restaurant, user, saved }: StepProps) => (
	<>
		<div className="row">
			<Input
				value={restaurant.name}
				onChange={(text) => onChange("name", text)}
				type="text"
				placeholder="Naam restaurant"
				className="full"
				label="Restaurant name"
				isRequired={saved && !restaurant.name}
			/>
			<CategoryDropdown
				isRequired={saved && !restaurant.category_id}
				value={restaurant.category_id}
				onChange={(value) => onChange("category_id", value)}
			/>
		</div>
		<div className="row">
			<Input
				value={restaurant.street}
				onChange={(text) => onChange("street", text)}
				type="text"
				name="street"
				placeholder="Adres"
				className="full"
				isRequired={saved && !restaurant.street}
			/>
			<Input
				value={restaurant.house_number}
				onChange={(text) => onChange("house_number", text)}
				isRequired={saved && !restaurant.house_number}
				type="text"
				name="house_number"
				placeholder="Nr."
			/>
		</div>
		<div className="row">
			<Input
				value={restaurant.zipcode}
				onChange={(text) => onChange("zipcode", text)}
				type="text"
				placeholder="1234 AB"
				isRequired={saved && !restaurant.zipcode}
				name="zipcode"
			/>
			<Input
				value={restaurant.city}
				onChange={(text) => onChange("city", text)}
				type="text"
				placeholder="Plaats"
				name="city"
				className="full"
				isRequired={saved && !restaurant.city}
			/>
		</div>
		<Input
			value={restaurant.telephone}
			onChange={(text) => onChange("telephone", text)}
			type="text"
			placeholder="Telefoonnummer"
			name="telephone"
		/>
		<Input
			value={restaurant.website}
			onChange={(text) => onChange("website", text)}
			type="text"
			placeholder="Website"
			name="website"
		/>
	</>
);

export default RegisterView;
