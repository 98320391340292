import React, { useState, useEffect, useRef } from "react";
import "./account.scss";
import Layout from "../../components/Layout";
import TabNav from "../../components/TabNav";
import { ACCOUNT_ROUTES } from "./UserEditView";
import { useSelector, useDispatch } from "react-redux";
import useAlert from "../../hooks/useAlert";
import usePatch from "../../hooks/rest/usePatch";
import { updateProfile } from "../../redux/auth";
import Card from "../../components/Card";
import StyledText from "../../components/StyledText";
import Input from "../../components/Input";
import CategoryDropdown from "../../components/Dropdown/CategoryDropdown";
import Button from "../../components/Button";
import useDelete from "../../hooks/rest/useDelete";
import FileUpload from "../../components/FileUpload";

const RestaurantEditView = () => {
	const auth = useSelector((state: any) => state.auth);
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();
	const [patchProfile, loading] = usePatch("/auth/profile");
	const [restaurant, setRestaurant] = useState({
		name: "",
		street: "",
		house_number: "",
		zipcode: "",
		city: "",
		description: "",
		category_id: undefined,
		telephone: "",
		...auth.restaurant,
	} as any);

	useEffect(() => {
		setRestaurant({
			...auth.restaurant,
		});
	}, [auth, setRestaurant]);

	const handleSave = () => {
		delete restaurant.media;
		patchProfile({ restaurant })
			.then(({ data }) => {
				dispatch(updateProfile(data));
				sendAlert({
					type: "success",
					title: "Uw restaurant profiel is aangepast",
				});
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
			});
	};

	const handleRestaurantChange = (key: string, value: any) => {
		setRestaurant({
			...restaurant,
			[key]: value,
		});
	};

	if (auth.is_admin) {
		return (
			<Layout noLine title="Mijn restaurant" type="admin">
				<TabNav routes={ACCOUNT_ROUTES} />
				<div className="container-center">
					<StyledText>
						U bent ingelogd op een admin account
					</StyledText>
				</div>
			</Layout>
		);
	}

	return (
		<Layout noLine title="Mijn restaurant" type="admin">
			<TabNav routes={ACCOUNT_ROUTES} />
			<div className="account-view restaurant-edit-view">
				<div>
					<Card type="no-shadow">
						<div className="card-head">
							<StyledText type="bold">Restaurant</StyledText>
						</div>
						<div className="info-row">
							<Input
								className="large"
								value={restaurant.name}
								onChange={(text) =>
									handleRestaurantChange("name", text)
								}
								type="text"
								placeholder="Naam"
								label="Restaurant name"
							/>

							<CategoryDropdown
								value={restaurant.category_id}
								onChange={(value) =>
									handleRestaurantChange("category_id", value)
								}
							/>
						</div>
						<div className="info-row">
							<Input
								className="large"
								value={restaurant.street}
								onChange={(text) =>
									handleRestaurantChange("street", text)
								}
								type="text"
								placeholder="Straat"
								label="Straat"
							/>
							<Input
								value={restaurant.house_number}
								onChange={(text) =>
									handleRestaurantChange("house_number", text)
								}
								type="text"
								placeholder="Huisnummer"
								label="Huisnummer"
							/>
						</div>
						<Input
							value={restaurant.zipcode}
							onChange={(text) =>
								handleRestaurantChange("zipcode", text)
							}
							type="text"
							placeholder="Postcode"
							label="Postcode"
						/>
						<Input
							value={restaurant.city}
							onChange={(text) =>
								handleRestaurantChange("city", text)
							}
							type="text"
							placeholder="Stad"
							label="Stad"
						/>
						<Input
							value={restaurant.telephone}
							onChange={(text) =>
								handleRestaurantChange("telephone", text)
							}
							type="tel"
							name="telephone"
							placeholder="Telefoonnummer"
							label="Telefoonnummer"
							isOptional
						/>
						<Input
							value={restaurant.website}
							onChange={(text) =>
								handleRestaurantChange("website", text)
							}
							type="text"
							placeholder="Website"
							label="Website"
							isOptional
						/>
						<Input
							value={restaurant.description}
							onChange={(text) =>
								handleRestaurantChange("description", text)
							}
							type="text"
							placeholder="Omschrijving"
							label="Omschrijving"
							isOptional
							multiline
						/>
					</Card>
				</div>

				<div>
					<Card type="no-shadow">
						<div className="card-head">
							<StyledText type="bold">Logo</StyledText>
							<Button
								loading={loading}
								onClick={handleSave}
								type="gray"
								isSave
							/>
						</div>
						<RestaurantAvatar />
					</Card>
					<Card type="no-shadow">
						<StyledText type="bold">Het restaurant</StyledText>
						<RestaurantGallery />
					</Card>
					<Card className="url-copy" type="no-shadow">
						<StyledText type="bold">Maak het makkelijk</StyledText>
						<StyledText>
							Kopieer de onderstaande link en plaats deze op jouw
							website. Bezoekers worden dan automatisch doorgeleid
							naar jou persoonlijke reserveringsmodule
						</StyledText>
						<CopyBar />
					</Card>
					<Card className="url-copy" type="no-shadow">
						<StyledText type="bold">
							Easyzeats op jouw eigen site
						</StyledText>
						<StyledText>
							Plak de volgend code op uw website.
						</StyledText>
						<CopyCode />
					</Card>
				</div>
			</div>
		</Layout>
	);
};

const CopyCode = () => {
	const [copied, setCopied] = useState(false);
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	const url = `${window.location.origin}/restaurants/profile/${restaurant.id}/timeslots/now`;
	const code = `<iframe width="400" height="800" src="${url}" frameborder="0"></iframe>`;
	const handleCopy = () => {
		setCopied(true);
		navigator.clipboard.writeText(code);
		setTimeout(() => {
			setCopied(false);
		}, 5000);
	};

	return (
		<Input
			value={code}
			onChange={() => {}}
			placeholder="Uw code"
			button={
				<Button icon={copied ? "fa fa-check" : ""} onClick={handleCopy}>
					{copied ? "Succesvol gekopieerd" : "Kopieer"}
				</Button>
			}
		/>
	);
};

// /restaurants/profile/28/timeslots/now
const CopyBar = () => {
	const [copied, setCopied] = useState(false);
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	const url = `${window.location.origin}/restaurants/profile/${restaurant.id}/timeslots/now`;
	const handleCopy = () => {
		setCopied(true);
		navigator.clipboard.writeText(url);
		setTimeout(() => {
			setCopied(false);
		}, 5000);
	};

	return (
		<Input
			value={url}
			onChange={() => {}}
			placeholder="Uw url"
			button={
				<Button icon={copied ? "fa fa-check" : ""} onClick={handleCopy}>
					{copied ? "Succesvol gekopieerd" : "Kopieer"}
				</Button>
			}
		/>
	);
};

const ImageUpload = ({ value, onSave, onDelete }: any) => {
	const [media, setMedia] = useState(value);
	const [deleteMedia, loadingDelete] = useDelete(`/media/${media?.id}`);

	return (
		<div className="image-upload">
			<FileUpload
				value={media || {}}
				onChange={(media: any[]) => {
					if (media.length) {
						let item = media.length ? media[0] : {};
						setMedia(item);
						onSave(item.id);
					}
				}}
			/>
			{media.id && (
				<div
					onClick={() =>
						deleteMedia().then(() => {
							setMedia({});

							if (onDelete) {
								onDelete(media.id);
							}
						})
					}
					className="icon-delete"
				>
					{loadingDelete ? (
						<i className="fal fa-spin fa-spinner-third"></i>
					) : (
						<i className="fal fa-trash-alt"></i>
					)}
				</div>
			)}
		</div>
	);
};

const RestaurantGallery = () => {
	const auth = useSelector((state: any) => state.auth);
	const gallery = useSelector((state: any) => state.auth.restaurant.media);
	// const [gallery, setGallery] = useState([] as any);
	const dispatch = useDispatch();
	const [patchProfile] = usePatch("/auth/profile");
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	const handleSave = (id: any) => {
		let gallery = restaurant?.media || [];
		let ids = gallery.map((i: any) => i.id);
		const media = ids.includes(id)
			? ids.filter((i: any) => i !== id)
			: [...ids, id];

		patchProfile({
			restaurant: {
				media,
			},
		}).then((res: any) => {
			dispatch(updateProfile(res.data));
		});
	};

	const handleDelete = (id: any) => {
		let gallery = restaurant?.media || [];
		let media = gallery.filter((i: any) => i.id !== id);
		dispatch(
			updateProfile({
				...auth,
				restaurant: {
					...auth.restaurant,
					media,
				},
			})
		);
	};

	return (
		<div className="restaurant-gallery">
			<ImageUpload
				onDelete={handleDelete}
				value={gallery[0] || {}}
				onSave={handleSave}
			/>
			<ImageUpload
				onDelete={handleDelete}
				value={gallery[1] || {}}
				onSave={handleSave}
			/>
			<ImageUpload
				onDelete={handleDelete}
				value={gallery[2] || {}}
				onSave={handleSave}
			/>
		</div>
	);
};

const RestaurantAvatar = () => {
	const dispatch = useDispatch();
	const auth = useSelector((state: any) => state.auth);
	const media = useSelector((state: any) => state.auth.restaurant.thumbnail);
	const [patchProfile] = usePatch("/auth/profile");

	const handleSave = (id: any) => {
		patchProfile({
			restaurant: {
				media_id: id,
			},
		}).then((res: any) => {
			dispatch(updateProfile(res.data));
		});
	};

	return (
		<div className="avatar">
			<ImageUpload
				value={media || {}}
				onDelete={() => {
					dispatch(
						updateProfile({
							...auth,
							restaurant: {
								...auth.restaurant,
								media_id: null,
							},
						})
					);
				}}
				onSave={(id: any) => handleSave(id)}
			></ImageUpload>
		</div>
	);
};

export default RestaurantEditView;
