import React, { useEffect, useRef } from "react";
import "./alert-container.scss";
import StyledText from "../StyledText";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { clearAlert } from "../../redux/alert";
import useBlur from "../../hooks/useBlur";
import Button from "../Button";
import { useState } from "react";
import TimeslotDropdown from "../Dropdown/TimeslotDropdown";
import Switch from "../Switch";

export type AlertProps = {
	type?: string;
	title: string;
	text?: string;
	stayOpen?: boolean;
	button?: string;
	onConfirm?: (t?: any) => void;
	showTimeslotDropdown?: boolean;
	filterId?: any;
};

const variants = {
	container: {
		enter: {
			opacity: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: 0.5,
			},
		},
		exit: { opacity: 0 },
	},
	alert: {
		enter: {
			y: 0,
			opacity: 1,
			scale: 1,
		},
		exit: {
			y: 200,
			scale: 0.2,
			opacity: 0,
		},
	},
};

const AlertContainer = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state: any) => state.alert);

	useEffect(() => {
		if (
			alert.title !== "" &&
			!alert.stayOpen &&
			alert.type !== "confirm" &&
			alert.type !== "subscription-cancel"
		) {
			setTimeout(() => {
				dispatch(clearAlert());
			}, 2000);
		}
	}, [alert, dispatch]);

	return (
		<AnimatePresence exitBeforeEnter>
			{alert.title && (
				<motion.div
					initial="exit"
					animate="enter"
					exit="exit"
					variants={variants.container}
					className="alert-container"
				>
					<Alert {...{ ...alert }} />
				</motion.div>
			)}
		</AnimatePresence>
	);
};

const Alert = ({
	title,
	type,
	text,
	button,
	onConfirm,
	showTimeslotDropdown,
	filterId,
}: AlertProps) => {
	const dispatch = useDispatch();
	const ref = useRef(null);
	const close = () => dispatch(clearAlert());
	const [params, setParams] = useState({
		timeslot: undefined,
		alert: undefined,
	}) as any;
	useBlur(ref, close);
	const classes = ["alert"];
	if (type) {
		classes.push(`alert-${type}`);
	}

	if (type === "subscription-cancel") {
		return (
			<motion.div
				ref={ref}
				variants={variants.alert}
				className={classes.join(" ")}
			>
				<StyledText className="alert-title" type="title">
					{title}
				</StyledText>
				{text && (
					<StyledText className="alert-description">
						{text}
					</StyledText>
				)}

				<div
					style={{
						display: "block",
						marginTop: 20,
					}}
				>
					<div className="alert-buttons">
						<Button
							type="gray"
							onClick={() => {
								close();
								if (onConfirm) {
									onConfirm({ type: "cancel" });
								}
							}}
						>
							Volledig opzeggen
						</Button>
						<Button
							type="success"
							icon="fal fa-check"
							iconPosition="right"
							onClick={() => {
								close();
								if (onConfirm) {
									onConfirm({ type: "free" });
								}
							}}
						>
							Omzetten naar gratis account
						</Button>
					</div>
				</div>
			</motion.div>
		);
	}

	return (
		<motion.div
			ref={ref}
			variants={variants.alert}
			className={classes.join(" ")}
		>
			<StyledText className="alert-title" type="title">
				{title}
			</StyledText>
			{text && (
				<StyledText className="alert-description">{text}</StyledText>
			)}
			{type === "confirm" ? (
				<div
					style={{
						display: "block",
						marginTop: 20,
					}}
				>
					{showTimeslotDropdown && (
						<>
							<TimeslotDropdown
								{...{ filterId }}
								placeholder="Reserveringen verplaatsen?"
								value={params.timeslot}
								onChange={(value) => {
									setParams({
										...params,
										timeslot: value,
									});
								}}
							/>
							{params.timeslot && (
								<div
									style={{
										marginTop: 20,
									}}
								>
									<Switch
										onChange={(value) =>
											setParams({
												...params,
												alert: value,
											})
										}
										value={params.alert}
										label="Gasten op de hoogte brengen"
									/>
								</div>
							)}
						</>
					)}
					<div className="alert-buttons">
						<Button type="gray" onClick={close}>
							Annuleren
						</Button>
						<Button
							type="success"
							icon="fal fa-check"
							iconPosition="right"
							onClick={() => {
								close();
								if (onConfirm) {
									onConfirm(params);
								}
							}}
						>
							{button || "Bevestigen"}
						</Button>
					</div>
				</div>
			) : (
				button && <Button onClick={close}>{button}</Button>
			)}
		</motion.div>
	);
};

export default AlertContainer;
