import React from "react";
import "./account.scss";
import Layout from "../../components/Layout";
import TabNav from "../../components/TabNav";
import { ACCOUNT_ROUTES } from "./UserEditView";
import useGet from "../../hooks/rest/useGet";
import StyledText from "../../components/StyledText";
import moment from "moment";
import Button from "../../components/Button";
import axios from "axios";
import useAlert from "../../hooks/useAlert";
import SubscriptionBox from "./SubscriptionBox";
import { useSelector } from "react-redux";

const InvoicesView = () => {
	const { is_whitelisted, is_admin } = useSelector(
		(state: any) => state.auth
	);
	const [result, loading] = useGet("/invoices");
	const [sendAlert] = useAlert();
	const invoices = result?.data || [];

	const getInvoice = (id: string) => {
		axios({
			url: `/invoices/${id}`,
			method: "GET",
		})
			.then((res) => {
				const url = res.data.data.invoice_pdf;
				window.open(url, "_blank"); //to open new page
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: "Factuur kan niet worden gedownload",
				});
			});
	};

	if (is_whitelisted || is_admin) {
		return (
			<Layout noLine title="Mijn restaurant" type="admin">
				<TabNav routes={ACCOUNT_ROUTES} />
				<div className="container-center">
					<StyledText>U heeft gratis toegang</StyledText>
				</div>
			</Layout>
		);
	}

	return (
		<Layout {...{ loading }} noLine title="Mijn restaurant" type="admin">
			<TabNav routes={ACCOUNT_ROUTES} />

			<div className="sub-box">
				<SubscriptionBox />
			</div>

			<div className="invoices-view">
				<h2>Facturen</h2>
				<div className="invoices-view-items">
					{!loading && invoices.length ? (
						invoices.map((invoice: any) => (
							<div
								key={invoice.id}
								className="invoices-view-item"
							>
								<div className="invoices-view-item-info">
									<StyledText type="bold">
										{moment(invoice.date).format(
											"DD MMMM YYYY"
										)}
									</StyledText>
									<StyledText>EasyZeats Partner</StyledText>
									<StyledText>{invoice.total}</StyledText>
								</div>
								<div className="invoices-view-item-actions">
									<div
										onClick={() => getInvoice(invoice.id)}
										className="pdf-icon"
									>
										<img
											src={require("../../assets/images/dashboard-icons/pdf.svg")}
											alt=""
										/>
									</div>
								</div>
							</div>
						))
					) : (
						<div className="invoices-view-empty">
							{loading ? (
								<i className="fal fa-spin fa-spinner-third"></i>
							) : (
								<StyledText>
									U heeft nog geen facturen
								</StyledText>
							)}
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default InvoicesView;
