import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import Card from "../../components/Card";
import "./legal.scss";
const TermsAndConditionsView = () => {
	return (
		<Layout isSearch className="legal container-small">
			<Card type="no-shadow">
				<h1>Algemene voorwaarden</h1>
				<p>
					Op deze pagina vindt u de algemene voorwaarden van
					Easyzeats, zoals deze beschikbaar is gesteld. In deze
					algemene voorwaarden geven wij aan onder welk voorbehoud wij
					de informatie op onze website aan u aanbieden.
				</p>
				<p>
					Zonder uitdrukkelijke schriftelijke toestemming van
					Easyzeats is het niet toegestaan tekst, logo&rsquo;s,
					fotomateriaal of andere materialen op deze website her te
					gebruiken. Het intellectueel eigendom berust bij Easyzeats.
				</p>

				<p>
					<strong>ARTIKEL 1. VOORWERP</strong>
				</p>
				<p>
					Easyzeats gevestigd op Martinus Nijhoffstraat 27, 3842 LM,
					Harderwijk met als BTW-nummer NL002238351B68, verder
					Easyzeats genoemd. Deze gebruikersovereenkomst bevat de
					bepalingen en de voorwaarden die van toepassing zijn op het
					gebruik van de informatie en diensten aangeboden door
					Easyzeats op www.wasyzeats.nl, verder Easyzeats genoemd. De
					gebruiker (zij het in hoedanigheid van bezoeker en/of
					publicerend bedrijf) van Easyzeats wordt geacht ingestemd te
					hebben met de bepalingen van deze gebruikersovereenkomst.
					Easyzeats behoudt zich het recht voor op elk ogenblik deze
					gebruikersovereenkomst te wijzigen. De geamendeerde
					gebruikersovereenkomst zal bekend gemaakt worden op
					Easyzeats en zal automatisch van kracht worden na
					publicatie.
				</p>
				<p>&nbsp;</p>
				<p>
					<strong>ARTIKEL 2. AANSPRAKELIJKHEID</strong>
				</p>
				<p>
					Voor de prijzen die op onze website staan, geldt dat wij
					streven naar een zo zorgvuldig mogelijke weergave van de
					realiteit en de bedoelde prijzen. Fouten die daarbij
					ontstaan en herkenbaar zijn als programmeer dan wel
					typefouten, vormen nooit een aanleiding om een contract dan
					wel overeenkomst met Easyzeats te mogen claimen of te
					veronderstellen.
				</p>
				<p>&nbsp;</p>
				<p>
					Easyzeats streeft naar een zo actueel mogelijke website.
					Mocht ondanks deze inspanningen de informatie van of de
					inhoud op onvolledig en of onjuist zijn, dan kunnen wij
					daarvoor geen aansprakelijkheid aanvaarden.
				</p>
				<p>&nbsp;</p>
				<p>
					De informatie en/of producten op deze website worden
					aangeboden zonder enige vorm van garantie en of aanspraak op
					juistheid. Wij behouden ons het recht voor om deze
					materialen te wijzigen, te verwijderen of opnieuw te
					plaatsen zonder enige voorafgaande mededeling. Easyzeats
					aanvaardt geen aansprakelijkheid voor enige informatie die
					op websites staat waarnaar wij via hyperlinks verwijzen.
				</p>
				<p>&nbsp;</p>
				<p>
					<strong>
						ARTIKEL 3. PRIVACY VOORWAARDEN EN BESCHERMING VAN
						PERSOONLIJKE GEGEVENS
					</strong>
				</p>
				<p>
					Meer info: privacy statement Easyzeats (link naar privacy
					statement)
				</p>
				<p>&nbsp;</p>
				<p>
					<strong>ARTIKEL 4. BEWIJS EN TOEPASSELIJK RECHT</strong>
				</p>
				<p>
					Het Nederlands recht is van toepassing. partijen aanvaarden
					dat de elektronische communicatie tussen hen beiden
					rechtskracht bezit van schriftelijk bewijs. In geval van
					geschillen of betwisting zijn enkel de rechtbanken van
					Nederland bevoegd. Easyzeats heeft als doel het voorzien
					horeca ondernemingen en hun gastenn van een
					gebruiksvriendelijk reserverings-platform waarbij de
					gebruiker volledige controle over en inzage heeft in zijn
					eigen gegevens en hij/zij enkel de informatie ontvangt
					waarvoor hij/zij expliciet gevraagd heeft. Indien u op een
					bepaalde manier als gebruiker ervaart dat deze doelstelling
					niet gehaald wordt, dan vragen wij u uw klacht te formuleren
					naar support@easyzeats.nl.
				</p>
				<p>&nbsp;</p>
			</Card>
		</Layout>
	);
};

export default TermsAndConditionsView;
