import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";

import nl from "date-fns/locale/nl"; // the locale you want

import "moment/locale/nl";
import "./days.scss";
// import Input from "../Input";
import StyledText from "../StyledText";
// import calendarSvg from "../../assets/images/icons/calendar.svg";

registerLocale("nl", nl); // register it with the name you want

type DaysProps = {
	value: Date;
	onChange: (value: Date) => void;
};

const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const Days = ({ value, onChange }: DaysProps) => {
	const [dates, setDates] = useState([] as any);

	useEffect(() => {
		if (!dates.length) {
			let total = 7;
			let start = moment(value);
			const items = [start];
			for (let i = 1; i < total; i++) {
				items.push(moment().add(i, "days"));
			}
			setDates(items);
		}
	}, [dates.length, value]);

	let isDifferent = false;
	let not_default = false;

	if (dates.length) {
		let v = moment(value).format("DD-MM-YYYY");
		if (
			v !== dates[0].format("DD-MM-YYYY") &&
			v !== dates[1].format("DD-MM-YYYY")
		) {
			isDifferent = true;
		}

		not_default =
			moment(value).format("DD-MM-YYYY") !==
				dates[0].format("DD-MM-YYYY") &&
			moment(value).format("DD-MM-YYYY") !==
				dates[1].format("DD-MM-YYYY");
	}

	return (
		<div className="days">
			<div className="days-label">
				<StyledText type="bold">
					{capitalize(moment(value).format("MMMM"))}
				</StyledText>
			</div>
			{dates.length ? (
				<ul className="days-items">
					<Day
						// date={dates[0].toDate()}
						label="Vandaag"
						active={
							moment(value).format("DD-MM-YYYY") ===
							dates[0].format("DD-MM-YYYY")
						}
						onClick={() => onChange(dates[0].toDate())}
					/>
					<Day
						// date={dates[1].toDate()}
						active={
							moment(value).format("DD-MM-YYYY") ===
							dates[1].format("DD-MM-YYYY")
						}
						label="Morgen"
						onClick={() => onChange(dates[1].toDate())}
					/>
					<li
						className={`select ${
							isDifferent ? "select-active" : ""
						}`}
					>
						<CustomDateButton
							onChange={(value) => {
								onChange(value);
							}}
							value={value}
							not_default={not_default}
							defaultValue={dates[2]}
						/>
					</li>
				</ul>
			) : (
				false
			)}
		</div>
	);
};

const CustomDateButton = ({
	not_default,
	onChange,
	value,
	defaultValue,
}: {
	not_default: boolean;
	onChange: (v: any) => void;
	value: Date;
	defaultValue?: any;
}) => {
	const ref = useRef(null);
	let label = not_default
		? moment(value).format("dddd DD MMMM")
		: "Meer data";

	return (
		<div {...{ ref }} className="custom-date-button">
			<DatePicker
				selected={not_default ? value : undefined}
				onChange={(date) => onChange(date)}
				locale="nl"
				customInput={
					<Day active={not_default} showCalendar {...{ label }} />
				}
			/>
		</div>
	);
};

type DayProps = {
	onClick?: () => void;
	showCalendar?: boolean;
	active: boolean;
	label: string;
};

const ucfirst = (s: string) => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

const Day = ({ onClick, active, label, showCalendar }: DayProps) => {
	return (
		<li onClick={onClick} className={`day ${active && "day-active"}`}>
			<StyledText>{ucfirst(label)}</StyledText>
		</li>
	);
};

export default Days;
