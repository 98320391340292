import React, { useState, useEffect } from "react";
import "./restaurants-slots-view.scss";
import { NavLink, useHistory, useParams } from "react-router-dom";
import StyledText from "../../../components/StyledText";
import TimeslotList from "../../../components/TimeslotList";
import moment from "moment";
import useGet from "../../../hooks/rest/useGet";

export const Head = ({
	title,
	toProfile,
}: {
	title: string;
	toProfile?: boolean;
}) => {
	const params = useParams() as any;
	const history = useHistory();
	const profileUrl = `/restaurants/profile/${params.id}/show`;
	return (
		<div className="reservate-head">
			<div className="container-small row">
				<div
					onClick={() =>
						toProfile ? history.push(profileUrl) : history.goBack()
					}
					className="back head-icon"
				>
					<i className="fal fa-arrow-left"></i>
				</div>
				<StyledText type="title">{title}</StyledText>

				<NavLink to={profileUrl} className="cancel head-icon">
					<i className="fal fa-times"></i>
				</NavLink>
			</div>
			{/* <div className="container-small">
				<StyledText type="title">{title}</StyledText>
			</div> */}
		</div>
	);
};

const RestaurantsSlotsView = () => {
	const params = useParams() as any;
	const history = useHistory();
	const [date, setDate] = useState(new Date());
	// const [data, loading] = useGet(`/restaurants/${params.id}`);
	// const restaurant = data?.data || {};
	useEffect(() => {
		if (date) {
			let format = moment(date).format("YYYY-MM-DD");
			if (params.date !== format) {
				let url = `/restaurants/profile/${params.id}/timeslots/${format}`;
				history.replace(url);
			}
		}
	}, [date, history, params.date, params.id]);

	const handleSelect = (item: any) => {
		if (item.id) {
			let format = moment(date).format("YYYY-MM-DD");
			let url = `/restaurants/profile/${params.id}/timeslots/${format}/reservation/${item.id}`;
			history.push(url);
		}
	};
	// if (loading) {
	// 	return <div></div>;
	// }

	return (
		<div className="restaurants-slots-view">
			<Head toProfile title="Reserveren" />
			<div className="restaurants-slots-view-body">
				<TimeslotList
					hasContainer
					selected={{ id: null }}
					onDateChange={setDate}
					onSelect={handleSelect}
					restaurantId={params.id}
				/>
			</div>
		</div>
	);
};

export default RestaurantsSlotsView;
