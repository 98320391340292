import React from "react";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./input.scss";
import StyledText from "../StyledText";

type InputProps = {
	value?: any;
	onChange: (val: any) => void;
	onBlur?: (val: any) => void;
	label?: string;
	placeholder?: string;
	className?: string;
	type?: string;
	button?: any;
	multiline?: boolean;
	isOptional?: boolean;
	time?: boolean;
	name?: string;
	date?: boolean;
	increments?: boolean;
	showLabel?: boolean;
	ref?: any;
	defaultValue?: any;
	isRequired?: boolean;
	max?: number;
};

const Input = ({
	value,
	label,
	placeholder,
	onChange,
	className,
	type,
	button,
	multiline,
	isOptional,
	time,
	name,
	date,
	increments,
	ref,
	showLabel,
	defaultValue,
	isRequired,
	onBlur,
	max,
}: InputProps) => {
	const renderInput = () => {
		if (button) {
			return (
				<div className="input-row">
					<input
						ref={ref}
						required={!isOptional}
						type={type || "text"}
						onChange={(e) => onChange(e.target.value)}
						value={value}
						name={name}
						placeholder={label ? label : placeholder || ""}
						onBlur={onBlur}
					/>
					{button}
				</div>
			);
		}

		if (time) {
			let v = value ? moment(value, "HH:mm") : (undefined as any);
			return (
				<TimePicker
					showMinute={true}
					defaultValue={v}
					value={v}
					showSecond={false}
					onChange={(e) => onChange(e)}
				/>
			);
		}
		if (date) {
			let dateValue =
				value && typeof value === "string"
					? moment(value).toDate()
					: null;
			return (
				<div className="date-picker">
					<DatePicker
						disabledKeyboardNavigation
						selected={dateValue || undefined}
						onChange={(date) =>
							onChange(moment(date).format("YYYY-MM-DD"))
						}
						locale="nl"
						dateFormat="dd-MM-yyyy"
						customInput={
							<div className="date-picker-input">
								<span
									className={`${
										dateValue ? "value" : "placeholder"
									}`}
								>
									{dateValue
										? moment(dateValue).format("DD-MM-YYYY")
										: placeholder || "DD-MM-YYYY"}
								</span>
							</div>
						}
					/>
				</div>
			);
		}
		if (increments) {
			return (
				<div className="increments">
					<div
						onClick={() => {
							let n = Number(value);
							if (n > 0) {
								onChange(n - 1);
							}
						}}
						className="increments-icon"
					>
						-
					</div>
					<input
						ref={ref}
						required={!isOptional}
						placeholder={label ? label : placeholder || ""}
						type={type || "text"}
						onChange={(e) => onChange(e.target.value)}
						value={value}
						name={name}
						{...{ max }}
					/>
					<div
						onClick={() => {
							let n = Number(value);
							if (n !== max) {
								onChange(n + 1);
							}
						}}
						className="increments-icon"
					>
						+
					</div>
				</div>
			);
		}

		return (
			<input
				ref={ref}
				required={!isOptional}
				placeholder={label ? label : placeholder || ""}
				type={type || "text"}
				onChange={(e) => onChange(e.target.value)}
				value={value}
				name={name}
			/>
		);
	};

	return (
		<div
			className={`input ${className || ""} input-${type} ${
				isRequired
					? increments
						? "input-increments-error"
						: "input-error"
					: ""
			} `}
		>
			{showLabel && (
				<StyledText type="label">
					{label}{" "}
					<span className="optional">
						{isOptional ? "(optioneel)" : ""}
					</span>
				</StyledText>
			)}
			{!multiline ? (
				renderInput()
			) : (
				<textarea
					name={name}
					required={!isOptional}
					placeholder={placeholder || ""}
					onChange={(e) => onChange(e.target.value)}
					value={value || ""}
				/>
			)}
			{/* {isRequired && <span className="error">Dit veld is verplicht</span>} */}
		</div>
	);
};

type InputButtonProps = {
	label: string;
	icon?: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
};

export const InputButton = ({
	label,
	icon,
	onClick,
	disabled,
}: InputButtonProps) => {
	const classes = ["input-button"];
	if (disabled) {
		classes.push("input-button-disabled");
	}
	if (icon) {
		classes.push("input-button-has-icon");
	}
	return (
		<button onClick={onClick} type="submit" className={classes.join(" ")}>
			{label}
			<div className="icon">
				<i className={`fas ${icon}`}></i>
			</div>
		</button>
	);
};

export default Input;
