import React, { useState } from "react";
import "./timeslot-form.scss";
import StyledText from "../StyledText";
import Input from "../Input";
import Button from "../Button";
import moment from "moment";
import usePost from "../../hooks/rest/usePost";
import useAlert from "../../hooks/useAlert";
import PersonSlider from "../PersonSlider";
import { useParams } from "react-router-dom";
import Dropdown from "../Dropdown";
import { useSelector } from "react-redux";

function validateEmail(email: string) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

const TimeslotForm = ({
	restaurantId,
	timeslot,
	onClear,
	refetch,
	hideCancel,
	isAdmin,
}: any) => {
	const { date } = useParams();
	const [sendAlert] = useAlert();
	const [success, setSuccess] = useState(false);
	const [saved, setSaved] = useState(false);
	const [validEmail, setValidEmail] = useState(false);

	const [postReservation, fetching] = usePost(
		`/restaurants/${restaurantId}/timeslots/${timeslot.id}/reservations`
	);
	const remaining = timeslot.remaining <= 10 ? timeslot.remaining : 10;
	const persons_allowed = isAdmin ? timeslot.remaining : remaining;
	const [reservation, setReservation] = useState({
		name: "",
		persons: 1,
		email: "",
		telephone: "",
		comment: "",
		is_same_household: undefined,
		date: date ? date : timeslot.query,
		household_comment: "",
	} as any);

	if (!timeslot.id) {
		return (
			<div className="timeslot-form-empty">
				<i className="fal fa-clock"></i>
				<StyledText>Kies eerst een tijd</StyledText>
			</div>
		);
	}

	const n_date = moment(date);
	let date_label = "";
	if (n_date.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) {
		date_label = "Vandaag";
	} else {
		date_label = n_date.format("DD MMM YYYY");
	}

	const handleSubmit = (e?: any) => {
		if (e) e.preventDefault();
		setValidEmail(validateEmail(reservation.email));
		if (
			!reservation.name ||
			!reservation.persons ||
			!reservation.email ||
			reservation.is_same_household === undefined
		) {
			if (!isAdmin) {
				setSaved(true);
				sendAlert({
					type: "error",
					title: "Oeps, er is iets misgegaan!",
					text: "Vul alle velden in",
				});
				return;
			}
		}

		if (!validateEmail(reservation.email)) {
			if (!isAdmin) {
				setSaved(true);

				sendAlert({
					type: "error",
					title: "Oeps, er is iets misgegaan!",
					text: "Uw e-mail is niet geldig",
				});
				return;
			}
		}

		if (
			!reservation.household_comment &&
			reservation.is_same_household === false &&
			!isAdmin
		) {
			setSaved(true);
			sendAlert({
				type: "error",
				title: "Oeps, er is iets misgegaan!",
				text: "Vul alle velden in",
			});
			return;
		}

		setSaved(false);

		postReservation(reservation)
			.then(() => {
				setSuccess(true);
				refetch();
				setReservation({
					name: "",
					persons: 1,
					email: "",
					telephone: "",
					comment: "",
				});
			})
			.catch((e) => {
				if (e.response && e.response.data) {
					let errors = e.response?.data?.errors;
					if (errors && errors.is_almost_full) {
						sendAlert({
							type: "error",
							title: "Oeps, er is iets misgegaan!",
							text: `Op deze tijd hebben we geen ${reservation.persons} plekken over`,
						});
					} else if (errors && errors.is_full) {
						sendAlert({
							type: "error",
							title: "Oeps, er is iets misgegaan!",
							text: "Er is niet genoeg plek beschikbaar",
						});
					}
				}
			});
	};

	if (success) {
		return (
			<div className="timeslot-form-success">
				<i className="fal fa-check"></i>
				<StyledText type="title">
					Uw reservering is bevestigd!
				</StyledText>
				<StyledText>U ontvangt een bevestiging via de mail</StyledText>
				<Button
					onClick={() => {
						setSuccess(false);
						onClear();
					}}
				>
					Nog een reservering maken
				</Button>
			</div>
		);
	}

	if (timeslot.remaining === 0) {
		return (
			<div className="timeslot-form-success">
				<StyledText type="title">Dit tijdvak is helaas vol</StyledText>
				<Button
					onClick={() => {
						onClear();
					}}
				>
					Tijdsvakken bekijken
				</Button>
			</div>
		);
	}

	return (
		<form onSubmit={handleSubmit} className="timeslot-form">
			<div className="timeslot-form-wrapper">
				<div className="timeslot-form-info">
					{/* <StyledText type="title">Reservatie bevestigen</StyledText>
					<StyledText>
						U staat op het punt om te reserveren voor{" "}
						{timeslot.time.start} tot {timeslot.time.end} op {day}{" "}
						{day_number} {month}
					</StyledText> */}
					<div className="tab">{date_label}</div>
					<div className="tab">
						{timeslot.time.start} - {timeslot.time.end}
					</div>
				</div>

				<Input
					label={"Uw naam"}
					name="name"
					placeholder="Bv: John Doe"
					value={reservation.name}
					isRequired={saved && !reservation.name}
					onChange={(name) =>
						setReservation({
							...reservation,
							name,
						})
					}
				/>
				<PersonSlider
					label={"Aantal personen"}
					max={persons_allowed}
					value={reservation.persons}
					onChange={(persons) =>
						setReservation({
							...reservation,
							persons,
						})
					}
				/>
				<Input
					type="email"
					label={"Uw email"}
					placeholder="Bv: johndoe@gmail.com"
					value={reservation.email}
					isRequired={saved && !isAdmin && !validEmail}
					onChange={(email) =>
						setReservation({
							...reservation,
							email,
						})
					}
				/>
				<Input
					type="telephone"
					isOptional
					name="telephone"
					label={"Uw telefoonnummer"}
					placeholder="Bv: telefoonnummer"
					value={reservation.telephone}
					onChange={(telephone) =>
						setReservation({
							...reservation,
							telephone,
						})
					}
				/>
				<Input
					isOptional
					multiline
					label={"Opmerkingen"}
					placeholder="Allergieën of anderen bijzonderheden"
					value={reservation.comment}
					onChange={(comment) =>
						setReservation({
							...reservation,
							comment,
						})
					}
				/>

				<div className="household-select">
					<StyledText>
						Bestaan alle personen uit één huishouden
					</StyledText>
					<Dropdown
						value={reservation.is_same_household}
						placeholder="Ja of nee"
						isRequired={
							saved &&
							reservation.is_same_household === undefined &&
							!isAdmin
						}
						data={[
							{ value: true, label: "Ja" },
							{ value: false, label: "Nee" },
						]}
						onChange={(val) =>
							setReservation({
								...reservation,
								is_same_household: val,
							})
						}
					/>
				</div>

				{reservation.is_same_household === false && (
					<Input
						isOptional
						multiline
						placeholder="Wat is de samenstelling van de huishoudens? Voorbeeld: 1x3 en 1x2 personen."
						value={reservation.household_comment}
						onChange={(household_comment) =>
							setReservation({
								...reservation,
								household_comment,
							})
						}
						isRequired={
							saved &&
							reservation.is_same_household === false &&
							!reservation.household_comment
						}
					/>
				)}

				{/* <div className="radio">
					<StyledText>
						Bestaan alle personen uit één huishouden
					</StyledText>
					<div className="radio-items">
						<StyledText
							className="item"
							onClick={() =>
								setReservation({
									...reservation,
									is_same_household: true,
								})
							}
						>
							<input
								type="radio"
								checked={reservation.is_same_household}
							/>{" "}
							Ja
						</StyledText>
						<StyledText
							className="item"
							onClick={() =>
								setReservation({
									...reservation,
									is_same_household: false,
								})
							}
						>
							<input
								type="radio"
								checked={!reservation.is_same_household}
							/>{" "}
							Nee
						</StyledText>
					</div>
				</div> */}

				<div className="buttons">
					<Button
						onClick={onClear}
						icon="fas fa-arrow-left"
						iconPosition="left"
						type="transparent"
					>
						Annuleren
					</Button>
					<Button
						loading={fetching}
						onClick={handleSubmit}
						iconPosition="right"
						icon="fas fa-check"
					>
						Bevestigen
					</Button>
				</div>
			</div>
		</form>
	);
};

export default TimeslotForm;
