import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "./admin-nav.scss";
import Logo from "../../Logo";
import StyledText from "../../StyledText";
import { logout } from "../../../redux/auth";
import { motion } from "framer-motion";
import ToggleButton from "./ToggleButton";
import useBlur from "../../../hooks/useBlur";
import useAlert from "../../../hooks/useAlert";

const transition = {
	duration: 0.4,
	delay: 0.2,
	ease: "easeInOut",
};

const variants = {
	open: {
		x: 0,
		transition,
	},
	closed: {
		x: "-85vw",
		transition,
	},
};

const AdminNav = ({ title }: { title?: string }) => {
	const { is_admin, is_whitelisted, is_subscribed } = useSelector(
		(state: any) => state.auth
	);
	const [expanded, setExpanded] = useState(false);
	const [sendAlert] = useAlert();
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [visible, setVisible] = useState(false);
	const navRef = useRef(null);
	useBlur(navRef, () => setVisible(false));
	useEffect(() => setVisible(false), [location]);

	const handleLogout = () => {
		setVisible(false);
		dispatch(logout());
		history.push("/login");
	};

	useEffect(() => setExpanded(false), [location]);

	return (
		<div ref={navRef}>
			<motion.div
				initial={false}
				animate={visible ? "open" : "closed"}
				className="admin-nav-header"
			>
				<div className="container">
					<div className="admin-nav-header-logo">
						<Logo type="circle-white-stacked" />
					</div>
					<div className="admin-nav-header-logo_mobile">
						{title ? (
							<StyledText type="bold">{title}</StyledText>
						) : (
							<Logo />
						)}
					</div>
					<ToggleButton
						onClick={() => {
							setVisible(!visible);
						}}
					/>
				</div>
			</motion.div>
			<motion.nav
				variants={variants}
				initial={false}
				animate={visible ? "open" : "closed"}
				className={`admin-nav ${expanded ? "admin-nav-expanded" : ""} ${
					visible && "admin-nav-slided"
				}`}
			>
				<motion.div
					variants={{
						open: {
							transition,
						},
						closed: {
							transition,
						},
					}}
					initial={false}
					animate={expanded ? "open" : "closed"}
					className="expand-icon"
				>
					<ToggleButton
						onClick={() => {
							setExpanded(!expanded);
						}}
					/>
				</motion.div>
				<NavLink to="/" className="admin-nav-logo">
					<Logo
						type={
							expanded ? "circle-white" : "circle-white-stacked"
						}
					/>
				</NavLink>
				<div className="admin-nav-items">
					{is_subscribed || is_whitelisted ? (
						<>
							<NavItem
								to="/dashboard"
								label="Dashboard"
								icon="chart-line"
								image={require("../../../assets/images/dashboard-icons/dashboard.svg")}
							/>
							<NavItem
								to="/reservations/today"
								label="Reserveringen"
								icon="envelope"
								image={require("../../../assets/images/dashboard-icons/reserveringen.svg")}
								className={
									location.pathname.includes("/reservations")
										? "admin-nav-item-active"
										: ""
								}
							/>
						</>
					) : (
						false
					)}

					<NavItem
						to="/account/restaurant"
						label={
							is_admin && !is_subscribed
								? "Mijn account"
								: "Mijn restaurant"
						}
						image={require("../../../assets/images/dashboard-icons/mijn-restaurant.svg")}
						icon="utensils"
						className={
							location.pathname.includes("/account")
								? "admin-nav-item-active"
								: ""
						}
					/>
					<NavItem
						to="/ideas"
						label={"Ideeënbus"}
						image={require("../../../assets/images/dashboard-icons/ideeenbus.svg")}
						icon="help"
					/>
					<NavItem
						to="/need-help"
						label={"Hulp nodig"}
						image={require("../../../assets/images/dashboard-icons/hulp-nodig.svg")}
						icon="help"
					/>
					{is_admin ? (
						<NavItem
							to="/admin/users"
							label="Gebruikers"
							icon="users"
						/>
					) : (
						false
					)}
					<NavItem
						onClick={handleLogout}
						label="Uitloggen"
						image={require("../../../assets/images/dashboard-icons/uitloggen.svg")}
						icon="sign-out"
					/>
				</div>
			</motion.nav>
		</div>
	);
};

type NavItemProps = {
	to?: string;
	label: string;
	icon: string;
	count?: number;
	onClick?: () => void;
	className?: string;
	image?: any;
};

const NavItem = ({
	to,
	label,
	icon,
	count,
	onClick,
	className,
	image,
}: NavItemProps) => {
	if (onClick) {
		return (
			<div onClick={onClick} className="admin-nav-item">
				<div className="icon">
					{image ? (
						<img src={image} alt="" />
					) : (
						<i className={`fal fa-${icon}`}></i>
					)}
				</div>
				<StyledText>{label}</StyledText>
			</div>
		);
	}

	return (
		<NavLink
			to={to || "/"}
			className={`admin-nav-item ${className || ""}`}
			activeClassName="admin-nav-item-active"
		>
			<div className="icon">
				{image ? (
					<img src={image} alt="" />
				) : (
					<i className={`fal fa-${icon}`}></i>
				)}
			</div>
			<StyledText>{label}</StyledText>
		</NavLink>
	);
};

export default AdminNav;
