import React, { useState, useEffect } from "react";
import "./subscription-view.scss";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth";

import { useHistory } from "react-router-dom";

import SubscriptionsView from "../Info/SubscriptionsView";

export const plans = [
	{
		id: "premium_monthly",
		price: 39,
		name: "Maandelijks",
	},
	{
		id: "premium_yearly",
		price: 365,
		name: "Jaarlijks",
	},
];

const SubscriptionView = () => {
	const auth = useSelector((state: any) => state.auth);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if (auth.id && auth.is_subscripted) {
			history.push("/dashboard");
		}
	}, [auth, history]);

	// let whitelist_wait = process.env.REACT_APP_USE_WHITELIST;
	// if (whitelist_wait === "true") {
	// 	return (
	// 		<div className="subscription-view-whitelist">
	// 			<div className="container-small">
	// 				<StyledText className="white">
	// 					Op dit moment werken wij met een whitelist
	// 				</StyledText>
	// 				<StyledText className="white">
	// 					Wacht totdat een medewerken van EasyZeats u toegang
	// 					heeft gegeven
	// 				</StyledText>
	// 				<Button
	// 					onClick={() => {
	// 						dispatch(logout());
	// 						history.push("/");
	// 					}}
	// 					type="transparent"
	// 				>
	// 					Uitloggen
	// 				</Button>
	// 			</div>
	// 		</div>
	// 	);
	// }
	return <SubscriptionsView />;
};

type PackageProps = {
	active?: boolean;
	onClick: () => void;
	name: string;
	price?: any;
};

export const Package = ({ active, name, price, onClick }: PackageProps) => (
	<Card
		type="no-shadow"
		onClick={onClick}
		className={`package ${active ? "package-active" : ""}`}
	>
		<StyledText type="bold">&euro;{price}</StyledText>
		<StyledText>{name}</StyledText>
	</Card>
);

export default SubscriptionView;
