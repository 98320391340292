import React from "react";
import "./reservation-modal.scss";
import Modal from "../Modal";
import TimeslotPicker from "../TimeslotPicker";
import { useSelector } from "react-redux";

const ReservationModal = ({ visible, onClose }: any) => {
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	return (
		<Modal
			className="reservation-modal"
			title="Reservering aanmaken"
			visible={visible}
			onClose={onClose}
		>
			<TimeslotPicker restaurantId={restaurant.id} />
		</Modal>
	);
};

export default ReservationModal;
