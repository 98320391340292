import React, { useState } from "react";
import "./subscriptions-view.scss";
import Layout from "../../../components/Layout";
import TitleBox from "../../../components/TitleBox";
import Package from "./Package";
import Button from "../../../components/Button";

const SubscriptionsView = () => {
	const [isMonthly, setIsMonthly] = useState(true);
	return (
		<Layout>
			<div className="subscriptions-view">
				<div className="header-gray">
					<div className="container">
						<TitleBox
							title="Eerst proberen, dan kiezen"
							text="Gratis proberen, maandelijks opzegbaar en geen fee per reservering."
						/>
						<div className="radio-buttons">
							<div
								onClick={() => setIsMonthly(true)}
								className={`option ${
									isMonthly && "option-active"
								}`}
							>
								<div className="radio">
									<div className="radio-circle"></div>
								</div>
								<label>Maandelijks</label>
							</div>
							<div
								onClick={() => setIsMonthly(false)}
								className={`option ${
									!isMonthly && "option-active"
								}`}
							>
								<div className="radio">
									<div className="radio-circle"></div>
								</div>
								<label>
									Jaarlijks{" "}
									<span className="light">
										/ &euro; 1,- per dag
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<Packages {...{ isMonthly }} hideSpecial={false} />
				</div>
			</div>
		</Layout>
	);
};

const POINTS = {
	free: [
		{ title: "Online reserveren", text: "Tot maximaal 15 stoelen per dag" },
		{
			title:
				"Reserveringsbeheer 24/7<br /> altijd & overal op elk apparaat",
		},
		{
			title: "Tijdvakken & flexibele bezetting",
			text: "Tot maximaal 3 tijdvakken & 5 stoelen per tijdsvak",
		},
		{
			title: "Live beschikbaarheid van de bezetting",
		},
		{
			title: "Geautomatiseerd mailing voor gast en restaurant",
		},
		{
			title: "Restaurantpagina op EasyZeats.nl",
		},
		{
			title: "Beheer van vacatures (Binnenkort beschikbaar)",
			text: "Tot maximaal 1 vacature",
		},
		{
			title: "Geen fee per reservering",
		},
	],
	paid: [
		{ title: "Online reserveren" },
		{
			title:
				"Reserveringsbeheer 24/7<br /> altijd & overal op elk apparaat",
		},
		{
			title: "Tijdvakken & flexibele bezetting",
		},
		{
			title: "Live beschikbaarheid van de bezetting",
		},
		{
			title: "Geautomatiseerd mailing voor gast en restaurant",
		},
		{
			title: "Restaurantpagina op EasyZeats.nl",
		},
		{
			title: "Beheer van vacatures (Binnenkort beschikbaar)",
		},
		{
			title: "Statistieken",
		},
		{
			title: "Geen fee per reservering",
		},
	],
};

export const Packages = ({
	isMonthly,
	hideSpecial,
}: {
	isMonthly: boolean;
	hideSpecial?: boolean;
}) => (
	<div className="packages">
		<Package
			title="Van het huis"
			description="Eenvoudig bereikbaar en een unieke online ervaring van jouw restaurant"
			prices={{
				monthly: 0,
				yearly: 0,
			}}
			button={{
				to: `/checkout/free`,
				label: "Proberen!",
			}}
			points={POINTS.free}
			{...{ isMonthly }}
		/>
		<Package
			isRecommended
			title="Specialiteit"
			description="Geautomatiseerd en eenvoudig reserveringsplatform"
			prices={{
				monthly: 39,
				yearly: 365,
			}}
			button={{
				to: `/checkout/${isMonthly ? "monthly" : "yearly"}`,
				label: "Gratis proberen",
			}}
			points={POINTS.paid}
			{...{ isMonthly }}
		/>
		{!hideSpecial && (
			<div>
				<div className="package package-special">
					<div className="package-head">
						<h3 className="package-title">Speciaal</h3>
						<div className="price-info">
							<span className="light">prijs</span>
							<p>Op aanvraag</p>
						</div>
					</div>
					<div className="package-description">
						<p>Voor grote ondernemingen of meerdere filialen</p>
						<span>
							Meerdere zaken onder één systeem? Neem contact op
							voor een offerte op maat.
						</span>
					</div>
					<div className="package-button">
						<Button type="white" to="/contact">
							Neem contact op
						</Button>
					</div>
				</div>
			</div>
		)}
	</div>
);

export default SubscriptionsView;
