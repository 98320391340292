import React, { useState } from "react";
import "./filter-modal.scss";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import StyledText from "../../../../components/StyledText";
import CheckBox from "../../../../components/CheckBox";
import STATUSES from "../../../../lib/statuses";
import { useEffect } from "react";
import TimeslotDropdown from "../../../../components/Dropdown/TimeslotDropdown";
import { useParams } from "react-router-dom";

interface FilterModalProps {
	visible: boolean;
	setVisible: (val: boolean) => void;
	params: {
		[key: string]: string | boolean | undefined;
	};
	setParams: (value: any) => void;
}

const FilterModal = ({
	visible,
	setVisible,
	params,
	setParams,
}: FilterModalProps) => {
	const { type } = useParams() as any;
	const [copied, setCopied] = useState(params || {});
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		setCopied(params || {});
	}, [params]);

	useEffect(() => {
		Object.keys(copied).map((key: string) => {
			let val = copied[key];
			if ((!params[key] && val) || params[key] !== val) {
				setIsChanged(true);
			}
		});
		return () => {
			setIsChanged(false);
		};
	}, [copied, params]);

	const handleChange = (key: string, value: any) => {
		setCopied({
			...copied,
			[key]: value,
		});
	};

	return (
		<>
			<Button onClick={() => setVisible(!visible)} isFilter type="gray" />
			<Modal
				className="filter-modal"
				title="Reservering filteren"
				onClose={() => {
					setVisible(false);
					setIsChanged(false);
				}}
				{...{ visible }}
			>
				{type === "future" && (
					<FilterBlock title="Datum">
						<Input
							className="date-input"
							date
							placeholder="Datum"
							onChange={(value) => handleChange("date", value)}
							value={copied?.date === undefined}
						/>
					</FilterBlock>
				)}
				<FilterBlock title="Status">
					<CheckBox
						onChange={(value) => handleChange("status", undefined)}
						label="Alle statusen"
						value={copied?.status === undefined}
					/>
					{Object.keys(STATUSES).map((key: string) => {
						if (key !== "NEW") {
							return (
								<CheckBox
									{...{ key }}
									onChange={(value) =>
										handleChange("status", key)
									}
									label={STATUSES[key].title}
									value={copied?.status === key}
								/>
							);
						}
						return false;
					})}
				</FilterBlock>
				<FilterBlock title="Tijdvak">
					<TimeslotDropdown
						isClear
						value={copied?.timeslot || undefined}
						onChange={(value) => handleChange("timeslot", value)}
					/>
				</FilterBlock>
				<FilterBlock title="Aantal personen">
					<Input
						showLabel
						label="Aantal personen"
						type="number"
						value={copied?.capacity || 0}
						placeholder="0"
						increments
						onChange={(val) => {
							handleChange("capacity", val);
						}}
					/>
				</FilterBlock>
				<FilterBlock title="Huishouden">
					<CheckBox
						onChange={(value) =>
							handleChange("same_household", undefined)
						}
						label="All opties"
						value={copied?.same_household === undefined}
					/>
					<CheckBox
						onChange={(value) =>
							handleChange("same_household", true)
						}
						label="Zelfde huishouden"
						value={copied?.same_household === true}
					/>
					<CheckBox
						onChange={(value) =>
							handleChange("same_household", false)
						}
						label="Verschillende huishouden"
						value={copied?.same_household === false}
					/>
				</FilterBlock>
				<div className="buttons-center">
					{isChanged && (
						<Button
							onClick={() => {
								setParams(copied);
								setVisible(false);
							}}
						>
							Filter toepassen
						</Button>
					)}
				</div>
			</Modal>
		</>
	);
};

const FilterBlock = ({ title, children }: { title: string; children: any }) => (
	<div className="filter-block">
		<div className="filter-block-head">
			<StyledText type="label">{title}</StyledText>
		</div>
		<div className="filter-block-body">{children}</div>
	</div>
);

export default FilterModal;
