import React from "react";
import Switch from "./index";
import { useSelector, useDispatch } from "react-redux";
import useAlert from "../../hooks/useAlert";
import usePatch from "../../hooks/rest/usePatch";
import { updateProfile } from "../../redux/auth";

const AllowEmail = () => {
	const [patchProfile] = usePatch("/auth/profile");
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();
	const auth = useSelector((state: any) => state.auth);

	const handleChange = (allow_email: boolean) => {
		patchProfile({ allow_email })
			.then(({ data }) => {
				dispatch(updateProfile(data));
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
			});
	};

	return (
		<Switch
			onChange={handleChange}
			value={auth.allow_email}
			label="Reservering mails ontvangen"
		/>
	);
};

export default AllowEmail;
