import React from "react";
import "./loading.scss";
import StyledText from "../StyledText";

const Loading = () => {
	return (
		<div className="loading">
			<i className="fal fa-spin fa-spinner-third"></i>
			<StyledText>Een ogenblik geduld...</StyledText>
		</div>
	);
};

export default Loading;
