import React, { useCallback, useState } from "react";
import axios from "axios";
import "./file-upload.scss";

import { useDropzone } from "react-dropzone";
import StyledText from "../StyledText";
import useAlert from "../../hooks/useAlert";

export default function FileUpload({
	onChange,
	value,
}: {
	onChange: (value: any) => void;
	value: any;
}) {
	const [loading, setLoading] = useState(false);
	const [sendAlert] = useAlert();
	const onDrop = useCallback(
		async (acceptedFiles) => {
			setLoading(true);
			// Do something with the file
			let promises = acceptedFiles.map((file: any) => {
				let fd = new FormData();
				fd.append("file", file);
				return axios.post("/media", fd).catch(() => {
					sendAlert({
						type: "error",
						title: "Fout met uploaden",
						text:
							"De afbeelding is te groot of kan niet worden verwerkt",
					});
					setLoading(false);
				});
			});

			let files = await Promise.all(promises);
			let media = files.map((file: any) => {
				return file.data.data;
			});
			onChange(media);
			setLoading(false);
		},
		[onChange, sendAlert]
	);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/jpeg, image/png",
	});

	if (loading) {
		return (
			<div className="file-upload">
				<i className="fal fa-spin fa-spinner-third"></i>
			</div>
		);
	}
	if (value && value.id) {
		return (
			<div className="file-upload-result">
				<img src={value.url} alt="" />
			</div>
		);
	}

	return (
		<div className="file-upload" {...getRootProps()}>
			<input {...getInputProps()} />
			<div className="icon">
				<i className="fas fa-image"></i>
			</div>
		</div>
	);
}
