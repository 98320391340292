import React from "react";
import "./reservation-detail-modal.scss";
import Modal from "../Modal";
import StyledText from "../StyledText";
import moment from "moment";
import {
	PersonCount,
	TimeslotIconButton,
} from "../../views/Dashboard/ReservationsView/MobileReservationItem";
import Badge from "../Badge";
import Button from "../Button";

const ReservationDetailModal = ({ reservation, onClose }: any) => {
	const oldContent = () => (
		<>
			<div className="info-row">
				<span className="label">Gereserveerd op</span>
				<StyledText>
					{moment(reservation.created_at).format("DD MMMM YYYY")}
				</StyledText>
			</div>
			<div className="info-row">
				<span className="label"></span>
				<div>
					<StyledText>
						<b>Van</b> {reservation?.timeslot?.time.start}
					</StyledText>
					<StyledText>
						<b>Tot</b> {reservation?.timeslot?.time.end}
					</StyledText>
				</div>
			</div>
			<div className="info-row">
				<span className="label">Voor</span>
				<StyledText className="date">
					{moment(reservation.date, "DD MM YYYY").format("dddd")}{" "}
					{moment(reservation.date, "DD MM YYYY").format("DD")}{" "}
					{moment(reservation.date, "DD MM YYYY").format("MMMM")}{" "}
					{moment(reservation.date, "DD MM YYYY").format("YYYY")}
				</StyledText>
			</div>
			<div className="info-row">
				<span className="label">Aantal personen</span>
				<StyledText>{reservation?.persons}</StyledText>
			</div>
			<div className="info-row info-block">
				<span className="label">Opmerking</span>
				<p></p>
				<StyledText>{reservation?.comment}</StyledText>
			</div>
			<div className="info-row">
				<span className="label">Zelfde huishouden</span>
				<StyledText>
					{reservation?.is_same_household ? "Ja" : "Nee"}
				</StyledText>
			</div>
			{!reservation?.is_same_household ? (
				<div className="info-row info-block">
					<span className="label">Samenstelling huishouden</span>
					<StyledText>
						{reservation?.household_comment || ""}
					</StyledText>
				</div>
			) : (
				false
			)}
		</>
	);

	return (
		<Modal
			title="Reservering details"
			visible={reservation.id}
			onClose={onClose}
		>
			<div className="reservation-detail-modal">
				<div className="user-info">
					<StyledText type="label">{reservation.name}</StyledText>
					<StyledText>
						{reservation?.email || "Geen email"}
					</StyledText>
					<StyledText>
						{reservation?.telephone || "Geen telefoon"}
					</StyledText>
				</div>
				<div className="info">
					<div className="info-part">
						<div className="row">
							<StyledText type="label">
								Gereserveerd op
							</StyledText>
							<StyledText>
								{moment(reservation.created_at).format(
									"DD MMMM YYYY"
								)}
							</StyledText>
						</div>
						<div className="row">
							<StyledText type="label">Voor</StyledText>
							<StyledText>
								{moment(reservation.date).format(
									"DD MMMM YYYY"
								)}
							</StyledText>
						</div>
						<div style={{ marginTop: 15 }} className="row">
							<PersonCount count={10} />
							<TimeslotIconButton
								time={`${reservation?.timeslot?.time.start} - ${reservation?.timeslot?.time.end}`}
							/>
						</div>
					</div>
					<div className="info-part info-part-block">
						<StyledText type="label">Opmerking</StyledText>
						<StyledText>
							{reservation?.comment || "Geen opmerking"}
						</StyledText>
					</div>
					<div className="info-part info-part-no-border">
						<div className="row row-justify">
							<StyledText type="label">Eén huishouden</StyledText>
							<Badge>
								{reservation?.is_same_household ? "Ja" : "Nee"}
							</Badge>
						</div>
						{!reservation?.is_same_household && (
							<StyledText>
								{reservation?.household_comment || ""}
							</StyledText>
						)}
					</div>
					<div className="cta-buttons">
						{reservation?.telephone && (
							<Button
								icon="fas fa-phone"
								isBlank
								type="secondary"
								to={`tel: ${reservation.telephone}`}
								className="button-flip-icon"
							>
								Bellen
							</Button>
						)}
						{reservation?.email && (
							<Button
								icon="fas fa-envelope"
								isBlank
								type="secondary"
								to={`mailto: ${reservation.email}`}
							>
								Mailen
							</Button>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ReservationDetailModal;
