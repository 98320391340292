import React from "react";
import TabNav from "../../components/TabNav";
import Layout from "../../components/Layout";
import TimeslotPickerV2 from "../../components/TimeslotPickerV2";
import { ACCOUNT_ROUTES } from "../Account/UserEditView";

export const OVERVIEWROUTES = [
	{
		to: "/dashboard/timeslots",
		label: "Tijdvakken & stoelen",
	},
];

const TimeslotPickerView = () => {
	return (
		<Layout noLine title="Mijn restaurant" type="admin">
			<TabNav routes={ACCOUNT_ROUTES} />
			<TimeslotPickerV2 />
		</Layout>
	);
};

export default TimeslotPickerView;
