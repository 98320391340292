import React from "react";
import Switch from "../Switch";
import { useSelector, useDispatch } from "react-redux";
import useAlert from "../../hooks/useAlert";
import usePost from "../../hooks/rest/usePost";
import useDelete from "../../hooks/rest/useDelete";
import { updateProfile } from "../../redux/auth";

const PauseRestaurant = () => {
	const auth = useSelector((state: any) => state.auth);
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();
	const [postPause] = usePost("/pause-restaurant");
	const [removePause] = useDelete("/pause-restaurant");

	const handleError = () =>
		sendAlert({ type: "error", title: "Er is iets misgegaan" });

	const handleChange = (value: boolean) => {
		if (value) {
			removePause()
				.then(() => {
					sendAlert({
						type: "succes",
						title: "U kunt weer reserveringen ontvangen",
					});
					setProfile(!value);
				})
				.catch(handleError);
		} else {
			postPause({})
				.then(() => {
					setProfile(!value);
					sendAlert({
						type: "success",
						title: "Uw reserveringen zijn tot morgen gepauzeerd",
					});
				})
				.catch(handleError);
		}
	};

	const setProfile = (is_paused: boolean) => {
		dispatch(
			updateProfile({
				...auth,
				restaurant: {
					...auth.restaurant,
					is_paused,
				},
			})
		);
	};

	return (
		<Switch
			onChange={handleChange}
			value={!auth.restaurant.is_paused}
			label="Online reserveringen"
		/>
	);
};

export default PauseRestaurant;
