import React, { useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//Auth Routes
import LoginView from "./views/Auth/LoginView/index";
import RegisterView from "./views/Auth/RegisterView";
//Dashboard Routes
import OverviewView from "./views/Dashboard/OverviewView";
import HomeView from "./views/HomeView";
import RestaurantsView from "./views/RestaurantsView";
import useGet from "./hooks/rest/useGet";
import { updateProfile } from "./redux/auth";
import SubscriptionView from "./views/SubscriptionView";
import TimeslotView from "./views/Dashboard/TimeslotView";
import TimeslotCreateView from "./views/Dashboard/TimeslotView/create";
import ReservationsView from "./views/Dashboard/ReservationsView";
import RestaurantsProfileView from "./views/RestaurantsView/RestaurantsProfileView";
import RestaurantsSlotsView from "./views/RestaurantsView/RestaurantsSlotsView";
import ReservationView from "./views/RestaurantsView/ReservationView";
import AlertContainer from "./components/AlertContainer";
import CustomerReservationView from "./views/CustomerReservationView";
import ResetPasswordView from "./views/Auth/ResetPasswordView";
import UserEditView from "./views/Account/UserEditView";
import InvoicesView from "./views/Account/InvoicesView";
import RestaurantEditView from "./views/Account/RestaurantEditView";
import NotFoundView from "./views/NotFoundView";
import TimeslotPickerView from "./views/TImeslotPickerView";
import PrivacyView from "./views/Legal/PrivacyView";
import TermsAndConditionsView from "./views/Legal/TermsAndConditionsView";
import UsersView from "./views/Admin/UsersView";
import ForPartnersView from "./views/Info/ForPartnersView/index";
import ContactView from "./views/Info/ContactView/index";
import SubscriptionsView from "./views/Info/SubscriptionsView";
import DashboardView from "./views/Dashboard/DashboardView";
import HelpView from "./views/HelpView";
import IdeaView from "./views/Dashboard/IdeaView";
import HelpDashboardView from "./views/Dashboard/HelpView";
import PauseTimeslotView from "./views/Dashboard/ReservationsView/PauseTimeslotView";
import CheckoutView from "./views/CheckoutView";

const RedirectToDashboardRoute = ({
	component: Component,
	auth,
	...rest
}: any) => (
	<Route
		{...rest}
		auth={auth}
		render={(props: any) =>
			!auth.id ? (
				<Component {...props} />
			) : (
				<Redirect
					to={
						auth.is_admin
							? LOGGEDIN_ADMIN_REDIRECT
							: LOGGEDIN_REDIRECT
					}
				/>
			)
		}
	/>
);

export const LOGGEDIN_ADMIN_REDIRECT = "/admin/users";
export const LOGGEDIN_REDIRECT = "/reservations/today";

const RedirectToSubscription = ({ component: Component, ...rest }: any) => {
	const auth = useSelector((state: any) => state.auth);
	let allow = false;

	if (auth.is_subscribed || auth.is_whitelisted || auth.is_admin) {
		allow = true;
	}

	return (
		<Route
			{...rest}
			auth={auth}
			render={(props: any) =>
				auth.id && !allow ? (
					<Redirect to="/subscription" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

const Routes = () => {
	const auth = useSelector((state: any) => state.auth);
	const dispatch = useDispatch();
	const [result, loading] = useGet("/auth/profile");
	const routes = [];

	//Get auth and set redux
	useEffect(() => {
		if (result && result.data) {
			dispatch(updateProfile(result.data));
		}
	}, [dispatch, result]);

	if (loading) {
		return <div></div>;
	}
	//Legal routes
	routes.push(
		<Route
			path="/privacy-policy"
			key="privacy"
			exact
			component={PrivacyView}
		/>
	);
	routes.push(
		<Route
			path="/terms-and-conditions"
			key="algemene"
			exact
			component={TermsAndConditionsView}
		/>
	);

	//Basic routes
	routes.push(<Route path="/" key="home" exact component={HomeView} />);
	routes.push(
		<Route path="/contact" key="contact" exact component={ContactView} />
	);
	routes.push(<Route path="/help" key="help" exact component={HelpView} />);
	routes.push(
		<Route
			path="/subscriptions"
			key="subscriptions"
			exact
			component={SubscriptionsView}
		/>
	);
	routes.push(
		<Route
			path="/checkout/:plan"
			key="checkout"
			exact
			component={CheckoutView}
		/>
	);

	routes.push(
		<Route
			path="/for-partners"
			key="for-partners"
			exact
			component={ForPartnersView}
		/>
	);

	routes.push(
		<Route
			path="/reset-password/:token?"
			key="reset-password"
			exact
			component={ResetPasswordView}
		/>
	);
	routes.push(
		<Route
			path="/restaurants/:type/:location?"
			key="restaurants"
			exact
			component={RestaurantsView}
		/>
	);
	routes.push(
		<Route
			path="/restaurants/profile/:id/show"
			key="restaurants/profile"
			exact
			component={RestaurantsProfileView}
		/>
	);
	routes.push(
		<Route
			path="/restaurants/profile/:id/:type?"
			key="restaurants/profile"
			exact
			component={RestaurantsProfileView}
		/>
	);
	routes.push(
		<Route
			path="/restaurants/profile/:id/timeslots/:date"
			key="restaurants/timeslots"
			exact
			component={RestaurantsSlotsView}
		/>
	);
	routes.push(
		<Route
			path="/restaurants/profile/:id/timeslots/:date/reservation/:timeslot"
			key="restaurants/timeslots"
			exact
			component={ReservationView}
		/>
	);
	routes.push(
		<Route
			path="/reservation/:hid"
			key="reservation/hid"
			exact
			component={CustomerReservationView}
		/>
	);
	routes.push(
		<RedirectToDashboardRoute
			auth={auth}
			path="/signup"
			key="signup"
			exact
			component={RegisterView}
		/>
	);
	routes.push(
		<RedirectToDashboardRoute
			auth={auth}
			path="/login"
			key="login"
			exact
			component={LoginView}
		/>
	);

	if (auth.id) {
		routes.push(
			<Route
				path="/subscription"
				key="subscription"
				exact
				component={SubscriptionView}
			/>
		);
		// routes.push(
		// 	<RedirectToSubscription
		// 		path="/timeslots"
		// 		key="timeslots"
		// 		exact
		// 		component={TimeslotView}
		// 	/>
		// );
		// routes.push(
		// 	<RedirectToSubscription
		// 		path="/timeslots/:date/create"
		// 		key="timeslots/create"
		// 		exact
		// 		component={TimeslotCreateView}
		// 	/>
		// );
		routes.push(
			<RedirectToSubscription
				path="/dashboard"
				key="dashboard"
				exact
				component={DashboardView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/ideas"
				key="ideas"
				exact
				component={IdeaView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/need-help"
				key="need-help"
				exact
				component={HelpDashboardView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/reservations/:type"
				key="reservations/today"
				exact
				component={ReservationsView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/reservations/pause/timeslot"
				key="reservations/pause/timeslot"
				exact
				component={PauseTimeslotView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/account/timeslots"
				key="account/timeslots"
				exact
				component={TimeslotPickerView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/account/timeslots/create/:type"
				key="account/timeslots/create/type"
				exact
				component={TimeslotPickerView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/account/timeslots/:id"
				key="/account/timeslots/id"
				exact
				component={TimeslotPickerView}
			/>
		);
		routes.push(
			<Route
				path="/account/user"
				key="account/user"
				exact
				component={UserEditView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/account/invoices"
				key="account/invoices"
				exact
				component={InvoicesView}
			/>
		);
		routes.push(
			<RedirectToSubscription
				path="/account/restaurant"
				key="account/restaurant"
				exact
				component={RestaurantEditView}
			/>
		);
	}

	if (auth.is_admin) {
		routes.push(
			<Route
				path="/admin/users"
				key="admin/users"
				exact
				component={UsersView}
			/>
		);
	}

	routes.push(<Route key="not-found" component={NotFoundView} />);

	return (
		<Router>
			<AlertContainer />
			<ScrollTop />
			<Switch>{routes}</Switch>
		</Router>
	);
};

const ScrollTop = () => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	return <></>;
};

export default Routes;
