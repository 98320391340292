const STATUSES: Statuses = {
	UPDATED: {
		title: "Gewijzigd",
		color: "#EC8300",
	},
	DELETED: {
		title: "Geannuleerd",
		color: "#B0222A",
	},
	NEW: {
		title: "",
		color: "#969F73",
	},
};

type Statuses = {
	[key: string]: Status;
};

type Status = {
	title: string;
	color: string;
};
export default STATUSES;
