import React, { useRef, useState } from "react";
import useBlur from "../../../../hooks/useBlur";
import STATUSES from "../../../../lib/statuses";
import { DeleteReservation, EditReservation } from "../ReservationTable";
import "./mobile-reservation-item.scss";

const MAX_CHAR_LENGTH = 17;

const truncate = (input: string) => {
	const length: number = input?.length || 0;
	return length > MAX_CHAR_LENGTH
		? `${input.substring(0, MAX_CHAR_LENGTH)}...`
		: input;
};

const MobileReservationItem = ({
	reservation,
	onShow,
	onDelete,
	refetch,
}: {
	reservation: any;
	onShow: any;
	onDelete: any;
	refetch: any;
}) => {
	const [toggled, setToggled] = useState(false);
	const ref = useRef(null);
	useBlur(ref, () => setToggled(false));
	const name = "mobile-reservation-item";
	const status = STATUSES[reservation.status];
	const { timeslot } = reservation;

	const toggle = () => setToggled(!toggled);

	return (
		<div
			{...{ ref }}
			style={{ borderLeftColor: status.color }}
			className={`${name} `}
		>
			<div className={`${name}-head`}>
				<div className="row">
					<PersonCount count={reservation.persons} />
					<p>{truncate(reservation.name)}</p>
				</div>
				<span style={{ color: status.color }} className="status">
					{status.title}
				</span>
			</div>
			<Line colors={["#cdcdcd", "#fff"]} />
			<div className={`${name}-body`}>
				<div className="row">
					<div className="image">
						<img
							src={require("../../../../assets/images/dashboard-icons/clock.svg")}
							alt=""
						/>
					</div>
					<p>
						{timeslot.time.start} - {timeslot.time.end}
					</p>
				</div>
				<div onClick={toggle} className="more-button">
					{toggled ? "Minder" : "Meer"}
				</div>
			</div>
			<div className={`${name}-actions ${toggled && "toggled"}`}>
				<div className="action">
					<div onClick={() => onShow(reservation)} className="icon">
						<img
							src={require("../../../../assets/images/dashboard-icons/person.svg")}
							alt=""
						/>
					</div>
				</div>
				<div className="action">
					<EditReservation
						{...{ reservation: reservation, refetch }}
					/>
				</div>
				<div className="action">
					<DeleteReservation
						{...{ reservation: reservation, onDelete }}
					/>
				</div>
				<Line vertical size="80%" colors={["#cdcdcd", "#fff"]} />
				<div className="action action-delete">
					<div onClick={toggle} className="close-button">
						<img
							src={require("../../../../assets/images/dashboard-icons/close.svg")}
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const PersonCount = ({ count }: { count: number }) => (
	<div className="person-count">
		<div className="image">
			<img
				src={require("../../../../assets/images/dashboard-icons/aantal-personen.svg")}
				alt="Personen"
			/>
		</div>
		<Line size="80%" vertical colors={["#8d8d8d", "#bfbfbf"]} />
		<p>{count}</p>
	</div>
);

export const TimeslotIconButton = ({ time }: { time: string }) => (
	<div className="person-count person-count-width">
		<div className="image">
			<img
				src={require("../../../../assets/images/dashboard-icons/clock-white.svg")}
				alt="Personen"
			/>
		</div>
		<Line size="80%" vertical colors={["#8d8d8d", "#bfbfbf"]} />
		<p>{time}</p>
	</div>
);

const Line = ({
	vertical,
	colors,
	size,
}: {
	vertical?: boolean;
	colors: string[];
	size?: any;
}) => {
	const name = "multi-line";
	const classes = [name];
	if (vertical) classes.push(`${name}-vertical`);
	if (!vertical) classes.push(`${name}-horizontal`);

	let style = {
		borderColor: colors[0],
		backgroundColor: colors[1],
		height: vertical ? size || "100%" : undefined,
		width: !vertical ? size || "100%" : undefined,
	};

	return <div {...{ style }} className={classes.join(" ")} />;
};

export default MobileReservationItem;
