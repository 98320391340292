import React from "react";
import TimeslotButton from "./TimeslotButton";
import Loading from "../Loading";
import StyledText from "../StyledText";
import Button from "../Button";
import { useParams } from "react-router-dom";

const TimeslotListV2 = ({ result, loading }: any) => {
	const { id, type: routeType } = useParams();
	const slots = result.data || [];
	return (
		<div
			className={`tp2-items ${id || routeType ? "tp2-form-is-open" : ""}`}
		>
			{slots.length ? (
				slots.map((slot: any) => (
					<TimeslotButton key={slot.id + "slot"} {...{ ...slot }} />
				))
			) : (
				<div className="tp2-loading">
					{loading ? (
						<Loading />
					) : (
						<>
							<StyledText>U heeft nog geen tijdvakken</StyledText>
							<div className="icon">
								<img
									src={require("../../assets/images/icons/clock.png")}
									alt=""
								/>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default TimeslotListV2;
