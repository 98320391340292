import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/browser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Import axios config
import "./lib/bootstrap";
import { unregister as unregisterServiceWorker } from "./serviceWorker";

//Import global styles
import "./styles/typography.scss";
import "./styles/template.scss";

//import redux store
import store from "./redux";

import Routes from "./Routes";
import useVh from "./hooks/useVh";

if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		dsn:
			"https://8e94f293473446c6b608e7e6f8378470@o409967.ingest.sentry.io/5283422",
	});
}

const App = () => {
	useVh();
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Routes />
		</MuiPickersUtilsProvider>
	);
};

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

unregisterServiceWorker();
